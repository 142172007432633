import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";
import  down  from  './down.png'
import  up  from  './up.png'


function MenuSimuladores() {
  return (
    <React.Fragment>
      <div className="menucontainer-g">
        <ul className="menu-pri">
          <li className="f">
            <Link to="/menuorganizacion">Organizacion<img src={down} className="down" alt=""/></Link>
          </li>
          <li className="f">
            <Link to="/menuservicios">Servicios<img src={down} className="down" alt=""/></Link>
          </li>
          <li className="f">
            <Link to="/menu">Simuladores<img src={up} className="down" alt=""/></Link>
            <ul className="s">
              <li>
              <Link to="/arrendamiento">simulador arriendo</Link>
              </li>
              <li>
              <Link to="/GastosNotariales">simulador notariales</Link>
              </li>
              <li>
              <Link to="/GastosHipoteca">simulador hipotecas</Link>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.bancodeoccidente.com.co/solicitarcredito/#/"
                >
                  simulador crédito bancario
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export { MenuSimuladores };

import React from "react";
import flecha from "./flecha.png";

function ArrquitecturaInfo(props) {


  return (
    <React.Fragment>
      {/* lista */}
      <p className="parrafo">
            Desarrollamos y atendemos sus proyectos de manera integral en todas
            sus etapas:
          </p>
          <br />
      <ul className="parrafo ">
        <img className="flecha" src={flecha} alt="" />
        <li>Diseño Arquitectónico</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Diseño Estructural</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Construcción</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Licencias de Construcción</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Legalización y Desenglobe de Propiedades</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Diseño de Interiores</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Ampliaciones</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Divisiones</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Restauraciones</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Modificaciones y/o Adecuaciones</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Recuperación de Espacios</li>
        <br />
      </ul>
    </React.Fragment>
  );
}

export {ArrquitecturaInfo}

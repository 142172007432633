import flecha from "./flecha.png";
import React from "react";

function PrestamosInfo() {
  return (
    <React.Fragment>
      {/* parrafo */}
      <p className="parrafo">
        Tramitamos su crédito con garantía hipotecaria, sin complejos tramites y
        a un bajo interés con aprobación inmediata y desembolso en 24 horas.
      </p>
      <br />
      {/* subtitulos */}
      <h4 className="subtitulo">
        Documentos Requeridos: INMUEBLES EN BOGOTÁ, D.C
      </h4>
      <br />
      {/* lista */}
      <ul className="parrafo">
        <img className="flecha" src={flecha} alt="" />
        <li>Primera copia de la escritura.</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Certificado de tradición y libertad con fecha de expedición no mayor a
          30 días
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Paz y salvo del IDU</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Paz y salvo del impuesto predial</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Certificado catastral</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Fotocopia de la cédula</li>
        <br />
        <h4 className="subtitulo">
          INMUEBLES FUERA DE BOGOTÁ, D.C.
        </h4>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li> Primera copia de la escritura</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Certificado de tradición y libertad con fecha de expedición no mayor a
          30 días
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Paz y salvo Departamental</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Paz y salvo Municipal</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Fotocopia de la cédula</li>
        <br />
      </ul>
    </React.Fragment>
  );
}

export { PrestamosInfo };

import React from "react";

function SobreNosotrosInfo() {
  return (
    <React.Fragment>
      {/* parrafo */}
      <p className="parrafos">
        Somos una compañía con más de 25 años de experiencia en el sector
        inmobiliario ofreciendo servicios y soluciones efectivas en ventas
        inmuebles, arrendamientos, avalúos, hipotecas, arquitectura y diseño;
        adicionalmente ofrecemos servicios de asesoría en las áreas, contable,
        financiera, tributaria y jurídica las cuales complementan la actividad
        inmobiliaria.
      </p>
      <br />
      {/* parrafo */}
      <p className="parrafos">
        Nuestra trayectoria sumada al desarrollo e implementación de procesos de
        alta calificación, nos ha permitido ser una de las compañías líderes en
        el sector, generando la confianza de nuestros clientes y a su vez hemos
        logrado contribuir al desarrollo Urbanístico del país en especial en la
        ciudad de Bogotá D.C.
      </p>
      <br />
      {/* parrafo */}
      <p className="parrafos">
        Contamos con excelentes y cómodas instalaciones en donde ofrecemos una
        atención personalizada e integral a nuestros clientes, con un equipo
        humano altamente calificado capaz de satisfacer de manera oportuna sus
        requerimientos. Por eso JJC INMOBILIARIA somos un mundo de posibilidades
        con soluciones agiles y oportunas.
      </p>
      <br />
    </React.Fragment>
  );
}

export { SobreNosotrosInfo };

import React from "react";


function MisionInfo() {
  return (
    <React.Fragment>
   
          <p className="parrafos">
            Brindar de manera integral servicios de asistencia y soporte en la
            gestión inmobiliaria, siendo efectivos y oportunos en todas las
            etapas de nuestra intervención, aplicando los mas altos estándares
            de calidad, con una propuesta de valor que genere la confianza de
            nuestros clientes contribuyendo con ello al desarrollo y
            reconocimiento del sector.
          </p>
          <br />

    </React.Fragment>
  );
}

export { MisionInfo };

import React from 'react';
import {Footerp} from '../Footerp'

import {InfoCasa} from '../InfoCasa'
import {Bot} from '../servisiosSeparados/Bot'
import Whatsapp from '../Whatsapp'


function Casas() {
  
    return (
        <React.Fragment>
            <div className="">
            
            <InfoCasa/>
            </div>
            <Bot/>
            <Footerp/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
        </React.Fragment>
    )
}

export{Casas}
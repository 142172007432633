import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material";



function formatNumber(number) {
  return new Intl.NumberFormat('en-US', {


  }).format(number)
}


export default function SimuladoreHipoteca() {
  const [sincua, setSincua] = useState('');
  const [cancelpatri, setCancelpatri] = useState('');
  const [canselafec, setCancelafec] = useState('');
  const [age, setAge] = useState('');
  const [loading, setLoading] = useState(true);
  const [simuladorlist, setSimuladorlist] = useState([])
  const [simular, setSimular] = useState("")
  const [valorhelp, setValorhelp] = useState("")
  const [hipohelp, setHipohelp] = useState("")
  const [hipohelpcua, setHipohelpcua] = useState("")


  useEffect(() => {
    axios.get('api/simuladores-vista').then(res => {
      console.log(res.data.simuladores);
      if (res.status === 200) {
        setSimuladorlist(res.data.simuladores)
      }
      setLoading(false)

    })

  }, []);
  console.log(simuladorlist)


  if (loading) {
    return <h2>Cargando...</h2>
  }
  else {

  }



  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      valor: data.get('valor'),
      interes: data.get('interes'),
      can_hipo: data.get('can_hipo'),
      cancel_hipo_sin_cuantia: data.get('cancel_hipo_sin_cuantia'),
      cancel_patri_familiar: data.get('cancel_patri_familiar'),
      cancel_afecta_vivenda_familiar: data.get('cancel_afecta_vivenda_familiar'),
      const_hipo_con_cuantia: data.get('const_hipo_con_cuantia'),
      const_hipo_sin_cuantia: data.get('const_hipo_sin_cuantia'),
    });
    let valorsoli = parseInt(data.get('valor'));
    let interprimermes = parseInt(data.get('interes'));
    let can_hipo = ""
    if (data.get('can_hipo')) {
      can_hipo = parseInt(data.get('can_hipo'));
    }
    else {
      can_hipo = 0
    }


    let cancel_hipo_sin_cuantia = data.get('cancel_hipo_sin_cuantia');
    let cancel_patri_familiar = data.get('cancel_patri_familiar');
    let cancel_afecta_vivenda_familiar = data.get('cancel_afecta_vivenda_familiar');


    let const_hipo_con_cuantia = ""
    if (data.get('const_hipo_con_cuantia')) {
      const_hipo_con_cuantia = parseInt(data.get('const_hipo_con_cuantia'));
    }
    else {
      const_hipo_con_cuantia = 0
    }


    let const_hipo_sin_cuantia = data.get('const_hipo_sin_cuantia');

    //operaciones

    let registro150 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 150
    let registro260 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 260
    let registro385 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 385
    let registro386 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 386


    let interesprimermes = valorsoli * interprimermes / 100
    let avaluoinmueble = valorsoli * simuladorlist.map(simuladores => simuladores.hipo_valor_avaluo) / 100
    let estudiodetitulos = valorsoli * simuladorlist.map(simuladores => simuladores.hipo_estudio_titulos) / 100
    let honorariosoficina = valorsoli * simuladorlist.map(simuladores => simuladores.hipo_comision_inmo) / 100
    let gastosescrituracion = valorsoli * simuladorlist.map(simuladores => simuladores.gn_escrituracion) / 100
    let ivanotaria = valorsoli * simuladorlist.map(simuladores => simuladores.gn_iva_servicios_notariales) / 10000
    let beneficencia = valorsoli * simuladorlist.map(simuladores => simuladores.notaria_beneficencia) / 100
    let registro = ''
    if (valorsoli < registro150) {
      registro = valorsoli * simuladorlist.map(simuladores => simuladores.notaria_actos_150) / 1000
    }
    else if (valorsoli < registro260) {
      registro = valorsoli * simuladorlist.map(simuladores => simuladores.notaria_actos_260) / 100
    }
    else if (valorsoli < registro385) {
      registro = valorsoli * simuladorlist.map(simuladores => simuladores.notaria_actos_385) / 100
    }
    else if (valorsoli > registro386) {
      registro = valorsoli * simuladorlist.map(simuladores => simuladores.notaria_actos_386) / 100
    }
    let minutahipoteca = simuladorlist.map(simuladores => simuladores.hipo_valor_minuta)
    let certificadolibertad = simuladorlist.map(simuladores => simuladores.hipo_valor_certificados)
    let copias = simuladorlist.map(simuladores => simuladores.hipo_valor_copias)



    let cancelacionhipotecaconcuantia = can_hipo * simuladorlist.map(simuladores => simuladores.gn_cancel_hipo_con_cuantia) / 100
    // if (can_hipo > 0) {
    //   cancelacionhipotecaconcuantia = can_hipo * simuladorlist.map(simuladores => simuladores.gn_cancel_hipo_con_cuantia) / 100
    // }
    // else {
    //   cancelacionhipotecaconcuantia = 0
    // }


    let cancelacionhipotecasincuantia = simuladorlist.map(simuladores => simuladores.gn_cancel_hipo_sin_cuantia)
    let cancelacionpatrimoniofamiliar = simuladorlist.map(simuladores => simuladores.gn_cancel_patri_familiar)
    let cancelacionafectacionfamiliar = simuladorlist.map(simuladores => simuladores.gn_cancel_afect_familiar)
    let constitucionhipotecaconcuantia = const_hipo_con_cuantia * simuladorlist.map(simuladores => simuladores.gn_consti_hipo_cuantia) / 100
    let constitucionhipotecasincuantia = simuladorlist.map(simuladores => simuladores.gn_consti_hipo_sin_cuantia)

    function createData(gastos, descuentos) {
      return { gastos, descuentos };
    }

    let total = ""

    let rows = [
      createData("Intereses primer mes", `${interesprimermes}`),
      createData("Avalúo inmueble", `${avaluoinmueble}`),
      createData("Estudio de titulos", `${estudiodetitulos}`),
      createData("Honorarios oficina", `${honorariosoficina}`),
      createData("Gastos de escrituracion", `${gastosescrituracion}`),
      createData("Iva notaria", `${ivanotaria}`),
      createData("Beneficencia", `${beneficencia}`),
      createData("Registro", `${registro}`),
      createData("Minuta hipoteca", `${minutahipoteca}`),
      createData("Certificado de libertad", `${certificadolibertad}`),
      createData("Copias", `${copias}`),
      createData("Cancelación hipoteca con cuantia", `${cancelacionhipotecaconcuantia}`),
      createData("Cancelación hipoteca sin cuantia", `${cancelacionhipotecasincuantia}`),
      createData("Cancelación patrimonio familiar", `${cancelacionpatrimoniofamiliar}`),
      createData("Cancelacion afectacion familiar", `${cancelacionafectacionfamiliar}`),
      createData("Constitucion hipoteca con cuantia", `${constitucionhipotecaconcuantia}`),
      createData("Constitucion hipoteca sin cuantia", `${constitucionhipotecasincuantia}`),
    ]

    console.log(const_hipo_con_cuantia)



    // primero
    if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias
      total = valorsoli - interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total esperado a recibir", `$ ${formatNumber(total)}`),
      ]


    }

    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total esperado a recibir", `$ ${formatNumber(total)}`),
      ]


    }

    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total esperado a recibir", `$ ${formatNumber(total)}`),
      ]


    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total esperado a recibir", `$ ${formatNumber(total)}`),
      ]


    }


    // segundo

    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }



    //  tecero

    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionpatrimoniofamiliar
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionpatrimoniofamiliar - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }


    //  cuarto

    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta


      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }


    //  quinto ojo subtotales y total

    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {
     
     
      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia
      
      total = valorsoli + totalresta
      
      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {
      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia- cancelacionhipotecaconcuantia
      
      total = valorsoli + totalresta
      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {
      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia- constitucionhipotecaconcuantia
      
      total = valorsoli + totalresta
      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {
      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      
      total = valorsoli + totalresta
      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]
    }

    // de a dos

    //  sexto


    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }


    //  septimo

    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }


    //  octavo

    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - constitucionhipotecasincuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - constitucionhipotecasincuantia- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - constitucionhipotecasincuantia- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - constitucionhipotecasincuantia- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }


    //  noveno


    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '20' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }

    //  decimo


    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '20' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionafectacionfamiliar - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }

    //  decimoprimero



    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar - constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '20' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - constitucionhipotecasincuantia - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }


    //  decimosegundo


    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '20' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }


    //  decimotercero

    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia < 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') < 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }
    else if (cancel_hipo_sin_cuantia === '10' && cancel_patri_familiar === '10' && cancel_afecta_vivenda_familiar === '10' && const_hipo_sin_cuantia === '10' && data.get('can_hipo') > 1 && const_hipo_con_cuantia > 1) {

      let totalresta = -interesprimermes - avaluoinmueble - estudiodetitulos - honorariosoficina - gastosescrituracion - ivanotaria - beneficencia - registro - minutahipoteca - certificadolibertad - copias - cancelacionafectacionfamiliar - cancelacionhipotecasincuantia - cancelacionpatrimoniofamiliar- constitucionhipotecaconcuantia - cancelacionhipotecaconcuantia
      total = valorsoli + totalresta

      rows = [
        createData("Intereses primer mes", `$ ${formatNumber(interesprimermes)}`),
        createData("Avalúo inmueble", `$ ${formatNumber(avaluoinmueble)}`),
        createData("Estudio de titulos", `$ ${formatNumber(estudiodetitulos)}`),
        createData("Honorarios oficina", `$ ${formatNumber(honorariosoficina)}`),
        createData("Gastos de escrituracion", `$ ${formatNumber(gastosescrituracion)}`),
        createData("Iva notaria", `$ ${formatNumber(ivanotaria)}`),
        createData("Beneficencia", `$ ${formatNumber(beneficencia)}`),
        createData("Registro", `$ ${formatNumber(registro)}`),
        createData("Minuta hipoteca", `$ ${formatNumber(minutahipoteca)}`),
        createData("Certificado de libertad", `$ ${formatNumber(certificadolibertad)}`),
        createData("Copias", `$ ${formatNumber(copias)}`),
        createData("Cancelación hipoteca sin cuantia", `$ ${formatNumber(cancelacionhipotecasincuantia)}`),
        createData("Cancelación patrimonio familiar", `$ ${formatNumber(cancelacionpatrimoniofamiliar)}`),
        createData("Cancelacion afectacion familiar", `$ ${formatNumber(cancelacionafectacionfamiliar)}`),
        createData("Constitucion hipoteca sin cuantia", `$ ${formatNumber(constitucionhipotecasincuantia)}`),
        createData("Constitucion hipoteca con cuantia", `$ ${formatNumber(constitucionhipotecaconcuantia)}`),
        createData("Cancelación hipoteca con cuantia", `$ ${formatNumber(cancelacionhipotecaconcuantia)}`),
        createData("SubTotal", `$ ${formatNumber(totalresta)}`),
        createData("Total", `$ ${formatNumber(total)}`),
      ]

    }







    if (data.get('valor') > 0 && data.get('interes') > 0 && cancel_hipo_sin_cuantia > 0 && cancel_patri_familiar > 0 && cancel_afecta_vivenda_familiar > 0 && const_hipo_sin_cuantia > 0) {
      setSimular(
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Gastos</TableCell>
                <TableCell align="right">Descuentos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.gastos}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.gastos}
                  </TableCell>
                  <TableCell align="right">{row.descuentos}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else {
      setSimular(
        <>
          <Typography sx={{ m: 2 }} margin="6" variant="subtitle1" component="div" color="red">
            el campo de "Valor solicitado" es requerido
          </Typography>
          <Typography sx={{ m: 2 }} margin="6" variant="subtitle1" component="div" color="red">
            el campo de "Interes" es requerido
          </Typography>
          <Typography sx={{ m: 2 }} margin="6" variant="subtitle1" component="div" color="red">
            los campos de "seleccion" son requeridos
          </Typography>
        </>
      )
    }
  }





  const handleChangepri = (event) => {
    setSincua(event.target.value);
  };
  const handleChangesec = (event) => {
    setCancelpatri(event.target.value);
  };
  const handleChangeter = (event) => {
    setCancelafec(event.target.value);
  };
  const handleChangecuar = (event) => {
    setAge(event.target.value);
  };
  const handlerChangevh = (event) => {
    setValorhelp(formatNumber(event.target.value));
  };
  const handlerChangehh = (event) => {
    setHipohelp(formatNumber(event.target.value));
  };
  const handlerChangehhcua = (event) => {
    setHipohelpcua(formatNumber(event.target.value));
  };

  return (
    <Box sx={{
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Card sx={{ maxWidth: 1275 }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Simulador de Hipoteca
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              A continuación encontrara un simulador de préstamo para que tenga una idea aproximada del valor a recibir mensualmente La información marcada con * es necesaria
            </Typography>
            <TextField
              label="Valor solicitado"
              id="outlined-start-adornment"
              name="valor"
              helperText={`$ ${valorhelp}`}
              onChange={handlerChangevh}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              label="Interes %"
              id="outlined-start-adornment"
              name="interes"
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
            <TextField
              label="Cancelacion Hipoteca con cuantia"
              id="outlined-start-adornment"
              name="can_hipo"
              helperText={`$ ${hipohelp}`}
              onChange={handlerChangehh}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel value={20} id="demo-simple-select-helper-label">Seleccione</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={sincua}
                name="cancel_hipo_sin_cuantia"
                label=""
                onChange={handleChangepri}
              >

                <MenuItem value={10}>Si</MenuItem>
                <MenuItem value={20}>No</MenuItem>


              </Select>
              <FormHelperText>Cancelación hipoteca sin cuantia</FormHelperText>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={cancelpatri}
                name="cancel_patri_familiar"
                label=""
                onChange={handleChangesec}
              >

                <MenuItem value={10}>Si</MenuItem>
                <MenuItem value={20}>No</MenuItem>


              </Select>
              <FormHelperText>Cancelación patrimonio familiar</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={canselafec}
                name="cancel_afecta_vivenda_familiar"
                label="Age"
                onChange={handleChangeter}
              >

                <MenuItem value={10}>Si</MenuItem>
                <MenuItem value={20}>No</MenuItem>


              </Select>
              <FormHelperText>Cancelacion de afectación vivienda familiar</FormHelperText>
            </FormControl>
            <TextField
              label="Constitucion Hipoteca con cuantia"
              id="outlined-start-adornment"
              name="const_hipo_con_cuantia"
              helperText={`$ ${hipohelpcua}`}
              onChange={handlerChangehhcua}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={age}
                name="const_hipo_sin_cuantia"
                label="Age"
                onChange={handleChangecuar}
              >

                <MenuItem value={10}>Si</MenuItem>
                <MenuItem value={20}>No</MenuItem>

              </Select>
              <FormHelperText>Constitucion hipoteca sin cuantia</FormHelperText>
            </FormControl>

          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained">Calcular</Button>
          </CardActions>
          <span>
            {simular}
          </span>
        </Card>

      </Box>
    </Box>

  );
}

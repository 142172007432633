import * as React from 'react';
import home from './gis_home.png'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import "leaflet/dist/images/marker-shadow.png";




const Map = () => {
    return (
        <>
            <MapContainer center={[4.700432, -74.0917037]} zoom={18} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[4.700432, -74.0917037]}>
                    <Popup>
                    <img src={home} alt='ico'  />
                    <p>jjc inmobiliaria</p>
                    </Popup>
                </Marker>
            </MapContainer>
        </>  

    )

}


export default Map
import React from 'react';
import arquitectura from "./arquitectura.png";
import {ArrquitecturaInfo} from "./ArquitecturaInfo"
import  downd  from  './downd.png'


function Arquitectura() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)

  }

  return (

      <React.Fragment>
        {/* eslint-disable-next-line */}
        <a name="arquitectura"/>
        <div className="contenedorservicios">
        <div>
          {/* imagen */}
          <img src={arquitectura} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 
          className="titulo-ventas"
          onClick={onActive}
          >Arquitectura y diseño<img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>
          {/* parrafo */}
          <div className={active? "activete" : "non-activete" }>
            <ArrquitecturaInfo/>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
}

export { Arquitectura };

import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";
import  down  from  './down.png'

function Menu() {
  return (
    <React.Fragment>
      <div className="menucontainer-g">
        <ul className="menu-pri">
          <li className="f">
            <Link to="/menuorganizacion">Organizacion <img src={down} className="down"  alt=""/></Link>
          </li>
          <li className="f">
            <Link to="/menuservicios">Servicios <img src={down} className="down"  alt=""/></Link>
          </li>
          <li className="f">
            <Link to="/menusimuladores">Simuladores <img src={down} className="down"  alt=""/></Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export { Menu };

import React, { useState } from 'react';
import './Buscar.css';
import icoCiudad from './ciudad.png'
import icoCategoria from './categoria.png'
import icoTipo from './tipo.png'

import { Button } from '../Button';
import { Slider } from './Slider';
import axios from 'axios';
import {  FormControl, FormHelperText, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { useEffect } from 'react';

import habitacion from '../Galeria/Habitaciones.png'
import bano from '../Galeria/Banos.png'
import metros from '../Galeria/metros.png'
import { GaleriaVentas } from '../GaleriaVentas';



function formatNumber(number){
    return new Intl.NumberFormat( 'en-US',  {
    
   
    }    ).format(number)
  }



function Buscar() {


    const [simuladorlist, setSimuladorlist] = React.useState([])
    const [sectoreslist, setSectores] = React.useState([])
    const [departamentoslist, setdepartamentos] = React.useState([])
    const [departamento, setdepartamento] = React.useState([])
    const [asesoreslist, setAsesores] = React.useState([])
    const [propietarioslist, setPropietario] = React.useState([])
    const [barrioslist, setBarrios] = React.useState([])


    const [ventalist, setVenta] = React.useState([])
    const [arriendolist, setArriendo] = React.useState([])
    const [busventa, setBventa] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const [inmueblelist, setInmueble] = React.useState([]);
    const [numex, setNumex] = useState("")



    const [resultados, setResultados] = React.useState([])
    const [bus, setBus] = React.useState([])

    


    React.useEffect(() => {
        axios.get('api/tipo_inmueble').then(res => {
            console.log(res.data.tipo_inmueble);

            if (res.status === 200) {
                setSimuladorlist(res.data.tipo_inmueble)
            }
            setLoading(false)


        })

        axios.get('api/Arriendos').then(res => {
            console.log(res.data.Arriendos);

            if (res.status === 200) {
                setArriendo(res.data.Arriendos)
            }
            setLoading(false)

        })

        axios.get('api/inmueble_venta').then(res => {
            console.log(res.data.inmueble_venta);

            if (res.status === 200) {
                setVenta(res.data.inmueble_venta)
            }
            setLoading(false)

        })
        axios.get('api/barrios').then(res => {
            console.log(res.data.barrios);

            if (res.status === 200) {
                setBarrios(res.data.barrios)
            }
            setLoading(false)



        })

        axios.get('api/asesor').then(res => {
            console.log(res.data.asesor);

            if (res.status === 200) {
                setAsesores(res.data.asesor)
            }
            setLoading(false)

        })

        axios.get('api/propietarios').then(res => {
            console.log(res.data.propietarios);

            if (res.status === 200) {
                setPropietario(res.data.propietarios)
            }
            setLoading(false)

        })




        axios.get('api/departamentos').then(res => {
            console.log(res.data.departamentos);

            if (res.status === 200) {
                setdepartamentos(res.data.departamentos)
            }
            setLoading(false)

        })
        axios.get('api/sectores').then(res => {
            console.log(res.data.sectores);

            if (res.status === 200) {
                setSectores(res.data.sectores)
            }
            setLoading(false)

        })

    }, []);


    let inmulist = bus || [];


    const handleInput = (event) => {


        // console.log(inmueblelist.departamento)

        setInmueble(event.target.value);

        console.log(inmueblelist)


    }

    const handleChangebventa = (event) => {
        setBventa(event.target.value);
        
        console.log(busventa)

        if(busventa === 'venta'){
            setBus(ventalist)
        }
        else if (busventa === 'arriendo'){
            setBus(arriendolist)
        }
        else {
            setBus(arriendolist + ventalist)
        }

    };
    const handleChangedepa = (event) => {
        setdepartamento(event.target.value);
        console.log(departamento)

       

        
    };

    const handleChangenumex = (event) => {
    
        setNumex(event.target.value)
            

      };



     const handlerchangebus = (event) => {

        console.log(inmulist)


        // if (busventa === 'venta'){
        //     setBus(ventalist)
        // }
        // else if (busventa === 'arriendo'){
        //     setBus(arriendolist)
        // }

        let res =inmulist.filter(x => x.departamento === departamento)

         res = res.filter(x => x.tipo_inmueble === inmueblelist)

         
         if (numex > 0){
         res = res.filter(x => parseInt(x.valor_venta) < numex || parseInt(x.valor_canon) < numex) 
         }
         
        
        



        setResultados(
            <div id="ventas" className="ventas-con bloque">

            {res.map((venta) => (
              <div id="cardVentas" className="card-ventas">
                <img src={`http://loginjjc.test/${venta.image}`} alt='casa' className='imagen-csa' />
                <p className='localizacion'>{(venta.departamento) + ', ' + (venta.barrio)}</p>
                <h3 className='valor'>{`$${formatNumber(venta.valor_venta || venta.valor_canon )}`}</h3>
                <p className='localizacion'>{(venta.departamento) + ', ' + (venta.barrio)}</p>
                <p className='localizacion'>{'Cod:'+(venta.id)}</p>
                <div className='contenedor-ico'>
                  <p className='habitaciones'><img src={habitacion} alt='ico' className='ico-hab' /> {venta.alcobas} Hab</p>
                  <p className='baños'><img src={bano} alt='ico' className='ico-baño' />{venta.baños} baños</p>
                  <p className='metros'><img src={metros} alt='ico' className='ico-metros' /> {venta.area_lote}m²</p>
                </div>
                <div className="cont">
                  <Button

                    contenido='ver mas'
                    ruta={`/ventas/${venta.id}`}
                  />
                </div>
              </div>
            ))}
           
          </div>

        )
     }

    return (
        <React.Fragment>
            <Slider></Slider>
            {/* <picture className="contp">
                    <source media="(min-width:950px)" srcSet={principaimgl}/>
                    <source media="(min-width:640px)" srcSet={principaimgm}/>
                    <img src={principaimgs} alt = 'casa' className='img-principal'/>
                </picture> */}
            <div className="botonbucar">
                
                <button type="button" className='buttonPri button-galeria' onClick={handlerchangebus}>Buscar</button>
            </div>
            <div className="contenedorpricipal">
                <div className='back-principalf'>
                    <h2 className='titulo-principalg'>Buscar</h2>
                    <form className='form-principalg'>
                        <div className="put-1">
                            <FormControl sx={{ m: 1, minWidth: 120 }}>


                                <Select
                                    className='formff'
                                    labelId=""
                                    id=""
                                    value={departamentoslist.departamento}
                                    name="tipo_inmueble"
                                    label=""
                                    defaultValue="Bogotá D.C."
                                    onChange={handleChangedepa}

                                >

                                    {departamentoslist.map((simulador) => (
                                        <MenuItem sx={{ mt: 5, top: 200 }} value={simulador.departamento}>{simulador.departamento}</MenuItem>
                                    )
                                    )}


                                </Select>
                                <FormHelperText className='white'>Seleccione el Departamento</FormHelperText>
                            </FormControl>
                            <img className='ico' src={icoCiudad} height='13px' width='13px' alt='' />
                        </div>
                        <div className="put-2">
                            <FormControl sx={{ m: 1, minWidth: 120 }}>

                                <Select
                                    className='formff'
                                    labelId=""
                                    id=""
                                    
                                    name="tipo_inmueble"
                                    label=""
                                    defaultValue='Venta'
                                    onChange={handleChangebventa}
                                >


                                    <MenuItem value={'Venta'}>Venta</MenuItem>
                                    <MenuItem value={'Arriendo'}>Arriendo</MenuItem>



                                </Select>
                                <FormHelperText className='white' >Seleccione tipo contrato</FormHelperText>
                                <img className='ico' src={icoCategoria} height='13px' width='13px' alt='' />
                            </FormControl>
                        </div>
                        <div className="put-3">
                            <FormControl sx={{ m: 1, minWidth: 120 }}>

                                <Select
                                    className='formff'
                                    labelId=""
                                    id=""
                                    value={simuladorlist.tipo_inmueble}
                                    name="tipo_inmueble"
                                    label=""
                                    onChange={handleInput}
                                    defaultValue="Casa"
                                >

                                    {simuladorlist.map((simulador) => (
                                        <MenuItem value={simulador.tipo_inmueble}>{simulador.tipo_inmueble}</MenuItem>
                                    )
                                    )}


                                </Select>
                                <FormHelperText className='white'  >Seleccione tipo de inmueble</FormHelperText>
                                <img className='ico' src={icoTipo} height='13px' width='13px' alt='' />
                            </FormControl>
                        </div>
                        <div className="put-4">
                            <TextField
                                className='formff'
                                required
                                type="number"
                                id="canon"
                                name="canon"
                                // type="number"
                                sx={{ m: 1, width: '25ch' }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={handleChangenumex}
                                
                            >
                            <FormHelperText className='white'  >Presupuesto</FormHelperText>
                            </TextField>
                        </div>
                        {/* <div className="put-5">
                            <input className="imputg precio" placeholder='  Código'/>
                            <img className ='ico' src={icoCodigo} height = '13px'width ='13px' alt=''/>
                        </div> */}

                    </form>
                </div>
            </div>
            <div>{resultados}</div>
        </React.Fragment>
    )
}


export { Buscar };

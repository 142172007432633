import flecha from "./flecha.png";
import React from "react";

function VentasInfo() {
  return (
    <React.Fragment>
      <p className="parrafo">
        Entendiendo la necesidad que tienen nuestros clientes que buscan un
        inmueble con características específicas como: ubicación, diseño, vías
        de acceso, entidades educativas, parques entre otros, realizamos un
        previo análisis de sus expectativas lo cual nos permite estructurar,
        optimizar y enfocar nuestros recursos, siendo cada día más efectivos.
      </p>
      <br />

      <p className="parrafo">
        Diseñamos un plan de acción y tenemos las herramientas que nos lleva a
        la venta de los inmuebles, mejorando la experiencia de nuestros clientes
        y logrando la satisfacción de sus necesidades.
      </p>
      <br />

      <p className="parrafo">
        En el proceso de gestión que realizamos para la venta de los inmuebles
        consideramos entre otros los siguientes aspectos:
      </p>
      <br />
      {/* lista */}
      <ul className="parrafo">
        <img className="flecha" src={flecha} alt="" />
        <li>
          Contrato de consignación y/o corretaje inmobiliario con el propietario
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Estimación del valor comercial del inmueble consignado a través de
          peritos avaluadores a fin de establecer el precio justo acorde al
          mercado.
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Estudio de títulos y diagnostico fiscal y tributario a fin de detectar
          posibles situaciones que requieran ser saneadas.
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Presentación y/o promoción de los inmuebles en medios físicos y
          virtuales especializados tales como: Periódicos, revistas, página web,
          portales inmobiliarios, redes sociales entre otros.
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Disponibilidad amplia de tiempo para las visitas y presentación de los
          inmuebles coordinada previamente con el propietario
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Elaboración de la promesa de compraventa con las clausulas y
          lineamientos de Ley y acompañamiento hasta la firma de la escritura.
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Elaboración de las actas de entrega del inmueble.</li>
        <br />
      </ul>
      {/* subtitulos */}
      <h4 className="subtitulo">
        Documentos Requeridos para consignar un inmueble para Venta: 
      </h4>
      <br />
      {/* lista */}
      <ul className="parrafo">
        <img className="flecha" src={flecha} alt="" />
        <li>
          Formulario de Consignación y/o Corretaje Inmobiliario diligenciado.
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Copia Escritura Pública.</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>
          Certificado de tradición y libertad con fecha de expedición no mayor a
          30 días
        </li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Paz y Salvo del IDU</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Copia Impuesto Predial del último año</li>
        <br />
      </ul>
    </React.Fragment>
  );
}

export { VentasInfo };

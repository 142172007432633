import React from "react";
import "./Servicios.css";
import venta from "./ventas.png";
import ventasm from "./ventasm.png";
import ventasl from "./ventasl.png";
import flecha from "./flecha.png";
import arriendo from "./arrendamiento.png";
import arriendom from "./arriendom.png";
import avauo from "./avaluo.png";
import hipoteca from "./hipoteca.png";
import juridico from "./juridico.png";
import financiera from "./financiera.png";
import arquitectura from "./arquitectura.png";
import Whatsapp from '../Whatsapp'
function Servicios() {
  return (
    <React.Fragment>
      <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
      <div className="contenedorservicios">
        {/* ventas */}
        {/*  eslint-disable-next-line */}
        <a name="ventass" />
        <div>
          {/* imagen */}
          <picture>
            <source media="(min-width:950px)" srcSet={ventasl} />
            <source media="(min-width:640px)" srcSet={ventasm} />
            <img src={venta} alt="ventas" className="img-ventas" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas">Ventas</h3>
          {/* parrafo */}
          <p className="parrafo">
            Entendiendo la necesidad que tienen nuestros clientes que buscan un
            inmueble con características específicas como: ubicación, diseño,
            vías de acceso, entidades educativas, parques entre otros,
            realizamos un previo análisis de sus expectativas lo cual nos
            permite estructurar, optimizar y enfocar nuestros recursos, siendo
            cada día más efectivos.
          </p>
          <br />

          <p className="parrafo">
            Diseñamos un plan de acción y tenemos las herramientas que nos lleva
            a la venta de los inmuebles, mejorando la experiencia de nuestros
            clientes y logrando la satisfacción de sus necesidades.
          </p>
          <br />

          <p className="parrafo">
            En el proceso de gestión que realizamos para la venta de los
            inmuebles consideramos entre otros los siguientes aspectos:
          </p>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>
              Contrato de consignación y/o corretaje inmobiliario con el
              propietario
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Estimación del valor comercial del inmueble consignado a través de
              peritos avaluadores a fin de establecer el precio justo acorde al
              mercado.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Estudio de títulos y diagnostico fiscal y tributario a fin de
              detectar posibles situaciones que requieran ser saneadas.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Presentación y/o promoción de los inmuebles en medios físicos y
              virtuales especializados tales como: Periódicos, revistas, página
              web, portales inmobiliarios, redes sociales entre otros.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Disponibilidad amplia de tiempo para las visitas y presentación de
              los inmuebles coordinada previamente con el propietario
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Elaboración de la promesa de compraventa con las cláusulas y
              lineamientos de Ley y acompañamiento hasta la firma de la
              escritura.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Elaboración de las actas de entrega del inmueble.</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">
            Documentos Requeridos para consignar un inmueble para Venta: 
          </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>
              Formulario de Consignación y/o Corretaje Inmobiliario
              diligenciado.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Escritura Pública.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Certificado de tradición y libertad con fecha de expedición no
              mayor a 30 días
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Paz y Salvo del IDU</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Impuesto Predial del último año</li>
            <br />
          </ul>
        </div>
        {/* arriendos */}
        {/*  eslint-disable-next-line */}
        <a name="arriendos" />
        <div>
          {/* imagen */}
          <picture>
            <source media="(min-width:640px)" srcSet={arriendom} />
            <img src={arriendo} alt="ventas" className="img-ventas" />
          </picture>
          <h3 className="titulo-ventas">Arrendamientos</h3>
          {/* parrafo */}
          <p className="parrafo">
            El proceso de arrendamiento de un inmueble requiere cada día más
            atención para no incurrir en riesgos de alto costo; es así que
            ofrecemos un servicio cómodo, rápido y especializado permitiendo
            ahorrar tiempo en trámites y brindando un elevado nivel de seguridad
            y tranquilidad a los propietarios.
          </p>
          <br />

          <p className="parrafo">
            En el proceso de gestión que realizamos para el arrendamiento de los
            inmuebles consideramos entre otros los siguientes aspectos:
          </p>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>
              Análisis y definición del valor del canon de arrendamiento,
              garantizando que sea
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              acorde a los precios del mercado, su fluctuación y que cumpla con
              la normatividad vigente.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Contrato de mandato y/o consignación del inmueble con el
              propietario
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Contrato de arrendamiento con el arrendatario.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Facturación, recaudo y pago del canon de arrendamiento y cuotas de
              administración
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Presentación y/o promoción de los inmuebles en medios físicos y
              virtuales especializados tales como: Periódicos, revistas, página
              web, portales inmobiliarios, redes sociales entre otros
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Disponibilidad amplia de tiempo para las visitas y presentación de
              los inmuebles coordinada previamente con el propietario.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Manejo de pólizas con Agencias de Seguros para contratos de
              arrendamiento asegurando el pago oportuno del canon de
              arrendamiento y de los servicios públicos.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Inventario físico del inmueble al iniciar y al finalizar el
              contrato asegurando su recibo y entrega en perfectas condiciones.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Control y validación del mantenimiento del inmueble a través de
              visitas periódicas y validación con el propietario las
              reparaciones a que haya lugar.
            </li>
            <br />
          </ul>
          {/* subtitulo */}
          <h4 className="subtitulo">
            Documentos Requeridos para consignar un inmueble para Arriendo:
            Formulario de Consignación y/o Mandato diligenciado 
          </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Escritura Pública.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Certificado de tradición y libertad con fecha de expedición no
              mayor a 30 días
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Paz y Salvo del IDU</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Impuesto Predial del último año</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia del RUT</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia de la Cédula</li>
            <br />
          </ul>
          {/* subtitulo */}
          <h4 className="subtitulo">
            Documentos Requeridos para tomar un inmueble en arrendamiento:  
          </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Escritura Pública.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Certificación Laboral vigente</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Recibos de Pago Nómina últimos tres meses</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Extractos Bancarios últimos tres meses</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Codeudor con Finca Raíz</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia de la Cédula</li>
            <br />
          </ul>
        </div>
        {/* Avaluos */}
        {/*  eslint-disable-next-line */}
        <a name="avaluos" />
        <div>
          {/* imagen */}
          <img src={avauo} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas">Avalúos</h3>
          {/* parrafo */}
          <p className="parrafo">
            A través de expertos peritos avaluadores con capacidad y formación
            profesional realizamos y preparamos avalúos de bienes inmuebles
            urbanos, rurales entre otros, mediante la aplicación de las buenas
            prácticas de valoración y siguiendo estándares de calidad, así como
            las Normas Técnicas Sectoriales (NTS) y Guías Técnicas Sectoriales
            (GTS) relacionadas con la actividad valuatoria.
          </p>
          <br />
          {/* subtitulos */}
          <h4 className="subtitulo">
            En nuestro proceso de gestión consideramos entre otros los
            siguientes avalúos: Avalúos Urbanos  
          </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Rurales</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Comerciales</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Industriales.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos - Valoraciones Empresariales</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Maquinaria y Equipos</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Hipotecarios</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Monumentos Históricos</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Avalúos Divisorios con base al nuevo código general del proceso
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Especiales</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos Certificados RAA</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Avalúos e Inventarios Activos Fijos bajo Normas NIIF</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">
            Entrega de Informes de Avalúos en 48 horas 
          </h4>
          <br />
        </div>
        {/* Prestamo sobre hipoteca*/}
        {/*  eslint-disable-next-line */}
        <a name="prestamos" />
        <div>
          {/* imagen */}
          <img src={hipoteca} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas">Préstamo sobre hipoteca</h3>
          {/* parrafo */}
          <p className="parrafo">
            Tramitamos su crédito con garantía hipotecaria, sin complejos
            trámites  y a un bajo interés con aprobación inmediata y desembolso
            en 24 horas.
          </p>
          <br />
          {/* subtitulos */}
          <h4 className="subtitulo">
            Documentos Requeridos: INMUEBLES EN BOGOTÁ, D.C  
          </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Primera copia de la escritura.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Certificado de tradición y libertad con fecha de expedición no
              mayor a 30 días
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Paz y salvo del IDU</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Paz y salvo del impuesto predial</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Certificado catastral</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Fotocopia de la cédula</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              INMUEBLES FUERA DE BOGOTÁ, D.C. Primera copia de la escritura
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Certificado de tradición y libertad con fecha de expedición no
              mayor a 30 días
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Paz y salvo Departamental</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Paz y salvo Municipal</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Fotocopia de la cédula</li>
            <br />
          </ul>
        </div>
        {/* Asesoria juridica*/}
        {/*  eslint-disable-next-line */}
        <a name="juridica" />
        <div>
          {/* imagen */}
          <img src={juridico} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas">Asesoría jurídica</h3>
          {/* parrafo */}
          <p className="parrafo">
            Asesoramos legalmente con expertos abogados especializados en las
            diferentes ramas del derecho.
          </p>
          <br />
          {/* subtitulos */}
          <h4 className="subtitulo">DERECHO CIVIL  </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Pertenencias – Posesión de Inmuebles.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Ejecutivos.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Hipotecarios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Divisorios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos de Enriquecimiento sin Justa causa.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Verbales.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Monitorios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos de Insolvencia</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">DERECHO DE FAMILIA  </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Sucesiones: Liquidación Herencia</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Venta Derechos Herenciales.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Alimentos: Fijación y/o Cobro cuota Alimentaria</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Unión Marital de Hecho: Convivencia entre compañeros.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Divorcios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Interdicciones.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Muerte Presunta.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Usufructos.</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">PENSIONES  </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Sobrevivientes.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Vejez o Jubilación.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Invalidez.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Gracia.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Indemnización Sustitutiva.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Devolución de saldos.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Incrementos por Personas a cargo.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Traslado Régimen.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Auxilio Funerario</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Pago a Herederos.</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">OTROS </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Prestaciones Laborales.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Seguridad Social Responsabilidad por Daños</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Negligencia Médica.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Fideicomisos Civiles.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Derechos de Petición.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Tutelas. </li>
            <br />
          </ul>
        </div>
        {/* Financiera*/}
        {/*  eslint-disable-next-line */}
        <a name="financiera" />
        <div>
          {/* imagen */}
          <img src={financiera} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas">
            Asesorías contables, Financieras, tributarias
          </h3>
          {/* parrafo */}
          <p className="parrafo">
            A través de nuestro servicio de consultoría y asesoramiento
            contable, financiero y tributario satisfacemos las necesidades de
            nuestros clientes en materia de:
          </p>
          <br />

          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Codificación, contabilización y análisis de operaciones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Elaboración de Estados Financieros. (Balance General, Estado de
              Resultados, Flujo de Efectivo, Estado de Cambio en el Patrimonio,
              Estado de Cambio en la Situación Financiera)
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Elaboración de reportes a entidades de control Impuestos (Renta,
              Retención en la Fuente, IVA, Timbre, GMF. Riqueza, Predial,
              Vehículos, Industria y Comercio)
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Elaboración de declaraciones tributarias Municipal y Nacional
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Liquidación y registro de importaciones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Revisoría Fiscal – Auditoría financiera Conversión de Estados
              Financieros a Normas internacionales NIIF
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Conciliaciones bancarias</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Análisis de cuentas de Balance</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Reportes a socios</li>
            <br />
          </ul>
        </div>
        {/* Arquitectura*/}
        {/*  eslint-disable-next-line */}
        <a name="arquitectura" />
        <div>
          {/* imagen */}
          <img src={arquitectura} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas">Arquitectura y diseño</h3>
          {/* parrafo */}
          <p className="parrafo">
            Desarrollamos y atendemos sus proyectos de manera integral en todas
            sus etapas:
          </p>
          <br />

          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Diseño Arquitectónico</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Diseño Estructural</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Construcción</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Licencias de Construcción</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Legalización y Desenglobe de Propiedades</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Diseño de Interiores</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Ampliaciones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Divisiones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Restauraciones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Modificaciones y/o Adecuaciones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Recuperación de Espacios</li>
            <br />
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export { Servicios };

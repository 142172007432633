import React from "react";
import avauo from "./avaluo.png";
import { AvaluosInfo} from "./AbaluosInfo"
import  downd  from  './downd.png'


function Avaluos() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }
  return (
    
    <React.Fragment>
      <div className="contenedorservicios">
        {/* eslint-disable-next-line */}
        <a name="avaluos" />
        <div>
          {/* imagen */}
          <img src={avauo} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas"  onClick={onActive}>Avalúos <img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>
          <div className={active? "activetw" : "non-activete" }>
            <AvaluosInfo/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export { Avaluos };

import React from 'react';
import {Principal} from '../Principal'
import {EncabezadoSecciones} from '../EncabezadoSecciones'
import {Galeria} from '../Galeria'
import {Footerp} from '../Footerp'
import { InfoInmo } from '../InfoInmo';
import Whatsapp from '../Whatsapp'
import Map from '../InfoCasa/Map';
import { Buscar } from '../Buscar';


function Home() {
    return (
        <React.Fragment>
            <Buscar></Buscar>
            <Principal/>
            <InfoInmo/>
            <EncabezadoSecciones
                titulo='Inmuebles destacados'
                />
            <Galeria/>
           
            <div style={{ marginTop: '80px', margin: '17px', display: 'flex', alignItems: 'center' }}>
                <Map/>
            </div>
            <Footerp/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>


        </React.Fragment>
    )
}

export {Home}
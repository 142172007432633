import arriendo from "./arrendamiento.png";
import arriendom from "./arriendom.png";
import { ArriendosInfo } from "./ArriendosInfo";
import React from "react";
import  downd  from  './downd.png'

function Arriendos() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }
  return (
    <React.Fragment>
      <div className="contenedorservicios">
        {/* eslint-disable-next-line */}
        <a name="arriendos" />
        <div>
          {/* imagen */}
          <picture>
            <source media="(min-width:640px)" srcSet={arriendom} />
            <img src={arriendo} alt="ventas" className="img-ventas" />
          </picture>
          <h3 onClick={onActive} className="titulo-ventas">Arrendamientos<img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>
         <div className={active? "activete" : "non-activete" }>
            <ArriendosInfo/>
         </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export { Arriendos };

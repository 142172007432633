import React from "react";


function VisionInfo() {
  return (
    <React.Fragment>
      
          {/* parrafo */}
          <p className="parrafos">
            Ser líderes en el sector inmobiliario y lograr consolidarnos como
            una empresa seria y confiable, comprometida con nuestros valores,
            política de calidad y a través de nuestra gestión e intervención
            seguir contribuyendo al mejoramiento de la calidad de vida de
            nuestros clientes y al desarrollo económico , social y ambiental de
            la comunidad y del país.
          </p>
          <br />
        
  
    </React.Fragment>
  );
}

export { VisionInfo };

import sobren from "./sobren.png";
import sobrem from "./sobrem.png";
import React from "react";
import {SobreNosotrosInfo} from "./SobreNosotrosInfo"
import  downd  from  './downd.png'

function SobreNosotrosSeparado() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }

  return (
    <React.Fragment>
      <div className="contenedorsobre">
        {/* sobre*/}
        <div>
          {/* eslint-disable-next-line */}
          <a name="sobre" />
          {/* imagen */}
          <picture>
            <source media="(min-width:640px)" srcSet={sobrem} />
            <img src={sobren} alt="ventas" className="img-venta" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas"  onClick={onActive}>Sobre nosotros<img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>
          <div className={active? "activete" : "non-activete" }>
            <SobreNosotrosInfo/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export { SobreNosotrosSeparado };

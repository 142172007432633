import flecha from "./flecha.png";
import React from "react";

function ArriendosInfo() {
    
  return (
    <React.Fragment>
         {/* parrafo */}
         <p className="parrafo">
            El proceso de arrendamiento de un inmueble requiere cada día más
            atención para no incurrir en riesgos de alto costo; es así que
            ofrecemos un servicio cómodo, rápido y especializado permitiendo
            ahorrar tiempo en trámites y brindando un elevado nivel de seguridad
            y tranquilidad a los propietarios.
          </p>
          <br />

          <p className="parrafo">
            En el proceso de gestión que realizamos para el arrendamiento de los
            inmuebles consideramos entre otros los siguientes aspectos:
          </p>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>
              Análisis y definición del valor del canon de arrendamiento,
              garantizando que sea
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              acorde a los precios del mercado, su fluctuación y que cumpla con
              la normatividad vigente.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Contrato de mandato y/o consign ación del inmueble con el
              propietario
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Contrato de arrendamiento con el arrendatario.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Facturación, recaudo y pago del canon de arrendamiento y cuotas de
              administración
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Presentación y/o promoción de los inmuebles en medios físicos y
              virtuales especializados tales como: Periódicos, revistas, página
              web, portales inmobiliarios, redes sociales entre otros
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Disponibilidad amplia de tiempo para las visitas y presentación de
              los inmuebles coordinada previamente con el propietario.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Manejo de pólizas con Agencias de Seguros para contratos de
              arrendamiento asegurando el pago oportuno del canon de
              arrendamiento y de los servicios públicos.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Inventario físico del inmueble al iniciar y al finalizar el
              contrato asegurando su recibo y entrega en perfectas condiciones.
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Control y validación del mantenimiento del inmueble a través de
              visitas periódicas y validación con el propietario las
              reparaciones a que haya lugar.
            </li>
            <br />
          </ul>
          {/* subtitulo */}
          <h4 className="subtitulo">
            Documentos Requeridos para consignar un inmueble para Arriendo:
            Formulario de Consignación y/o Mandato diligenciado 
          </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Escritura Pública.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Certificado de tradición y libertad con fecha de expedición no
              mayor a 30 días
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Paz y Salvo del IDU</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Impuesto Predial del último año</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia del RUT</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia de la Cédula</li>
            <br />
          </ul>
          {/* subtitulo */}
          <h4 className="subtitulo">
            Documentos Requeridos para tomar un inmueble en arrendamiento:  
          </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Copia Escritura Pública.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Certificación Laboral vigente</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Recibos de Pago Nómina últimos tres meses</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Extractos Bancarios últimos tres meses</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Codeudor con Finca Raíz</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Copia de la Cédula</li>
            <br />
          </ul>
    </React.Fragment>
     );
    }
    
    export { ArriendosInfo };
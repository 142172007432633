import React from 'react';
import './Principal.css';
// import principaimgs from './carrucel/img/1.jpeg';
import principaimgm from './carrucel/img/1.jpeg';
import principaimgl from './carrucel/img/1.jpeg';
// import icoCiudad from './ciudad.png'
// import icoCategoria from './categoria.png'
// import icoTipo from './tipo.png'
// import icoPrecio from './precio.png'
// import icoCodigo from './codigo.png'
// import { Button } from '../Button';
// import { FormControl, Input, InputAdornment, InputLabel, MenuItem, TextField } from '@mui/material';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
// import AddBusinessIcon from '@mui/icons-material/AddBusiness';
// import PaidIcon from '@mui/icons-material/Paid';
import apartamentos from './carrucel/img/apto.jpg';
import casas from './carrucel/img/casa.jpg';
import proyectosNuevos from './carrucel/img/proyectos.jpg';
import lotes from './carrucel/img/lotes.jpg';
import fueraCiudad from './carrucel/img/fuera.jpg';
import { Link } from 'react-router-dom';







function Principal() {
    return (
        <React.Fragment>
            <picture className="contp">
                {/* <Example className="img-principal"/> */}
                <source media="(min-width:950px)" srcSet={principaimgl} />
                <source media="(min-width:640px)" srcSet={principaimgm} />
                {/* <img src={principaimgs} alt='casa' className='img-principal' /> */}
            </picture>
                <div className="cont-principal">
                    <div className="cont-principal-text">
                        <h1>NUESTRA VARIEDAD DE INMUEBLES</h1>
                       
                    </div>
                    <div className="parent">  
                        <div className="cont-principal-grid-apartamentos">
                        <Link to= '/busqueda_apartamentos'>
                            <h1 className='tit-cov'>Apartamentos</h1>
                            <img src={apartamentos} alt='apartamentos' className='img-apartamento' />
                        </Link>
                        </div>
                        <div className="cont-principal-grid-casas">
                            <Link to= '/busqueda_casas'>
                            <h1 className='tit-cov'>Casas</h1>
                            <img src={casas} alt='casas' className='img-casar' />
                            </Link>
                        </div>
                        <div className="cont-principal-grid-proyectos">
                        <Link to= '/busqueda_nuevos'>
                            <h1 className='tit-cov'>Proyectos Nuevos</h1>
                            <img src={proyectosNuevos} alt='Proyectos Nuevos' className='img-Proyectos'/>
                            </Link>
                        </div>
                        <div className="cont-principal-trip-lotes">
                        <Link to= '/busqueda_lotes'>
                            <h1 className='tit-cov'>Lotes</h1>
                            <img src={lotes} alt='lotes' className='img-lotes' />
                        </Link>
                        </div>
                        <div className="cont-principal-trip-fuera">
                        <Link to= '/busqueda_fuera'>
                            <h1 className='tit-cov'>Fuera de Bogotá</h1>
                            <img src={fueraCiudad} alt='fueraCiudad' className='img-fuera' />
                        </Link>
                        </div>
                    </div>



                </div>
            {/* <div className="botonbucar">
                <Button contenido='buscar' ruta="/busqueda" />
            </div> */}
            {/* <div className="contenedorpricipal">
                <div className='back-principal'>
                    <h2 className='titulo-principal'><span className='titulo-principal_un'> BUSCA</span>  <span >  TU MEJOR OPCION</span></h2>
                    <form className='form-principal'>
                        <div className="put-1"> */}
                            {/* <FormControl variant="standard" >
                                <InputLabel htmlFor="input-with-icon-adornment" sx={{ color: '#ffffff' }}>
                                    Ciudad
                                </InputLabel>
                                <Input
                                    id="input-with-icon-adornment"
                                    sx={{ color: '#ffffff' }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LocationCityIcon sx={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl variant="standard" >
                                <InputLabel htmlFor="input-with-icon-adornment" sx={{ color: '#ffffff' }}>
                                    Categoria
                                </InputLabel>
                                <Input
                                    sx={{ color: '#ffffff' }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start" sx={{ color: '#ffffff' }}>
                                            <AddBusinessIcon sx={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl variant="standard" >
                                <InputLabel htmlFor="input-with-icon-adornment" sx={{ color: '#ffffff' }}>
                                    Precio
                                </InputLabel>
                                <Input
                                    sx={{ color: '#ffffff' }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <PaidIcon sx={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl> */}
                            {/* <input className="imput" placeholder='  Ciudad' /> */}
                            {/* <img className='ico' src={icoCiudad} height='13px' width='13px' alt='' /><imput /> */}
                            {/* <input className="imput" placeholder='  Categoria' />  */}
                             {/* <img className='ico' src={icoCategoria} height='13px' width='13px' alt='' /> */}
                            {/* <input className="imput" placeholder='  Tipo de inmueble' /> */}
                            {/* <img className='ico' src={icoTipo} height='13px' width='13px' alt='' /> */}
                            {/* <input className="imput precio" placeholder='  Precio' /> */}
                            {/* <img className='ico' src={icoPrecio} height='13px' width='13px' alt='' /> */}
                        {/* </div>
                        <div className="put-2"> */}
                            {/* <TextField
                                color='secondary'
                                sx={{ color: '#ffffff' }}
                                id="outlined-select-currency"
                                select
                                label="Select"
                                
                                
                            >
                                
                                    <MenuItem  value= "ciudad">
                                       Ciudad
                                    </MenuItem>
                                
                            </TextField> */}
                        {/* </div>
                        <div className="put-3">
                        </div>
                        <div className="put-4">
                        </div>
                        <div className="put-5">
                            <input className="imput precio" placeholder='  Busqueda' />
                            <img className='ico' src={icoCodigo} height='13px' width='13px' alt='' />
                        </div> */}

                    {/* </form>
                </div>
            </div> */}
        </React.Fragment>
    )
}


export { Principal };

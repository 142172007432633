import React from "react";
import valor from "./valor.png"
import valorm from "./valorem.png"
import {ValorInfo} from "./ValorInfo"
import  downd  from  './downd.png'

function Valor() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }

    return (
      <React.Fragment>
        <div className="contenedorsobre">
          {/* eslint-disable-next-line */}
        <a name="valor"/>
          <picture>
          <source media="(min-width:640px)" srcSet={valorm} />
            <img src={valor} alt="ventas" className="img-venta" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas" onClick={onActive}>Valores<img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>
          <div className={active? "activete" : "non-activete" }>
            <ValorInfo/>
          </div>
        </div>
      
    </React.Fragment>
  );
}

export { Valor };
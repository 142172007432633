import React from 'react';
import {Footerp} from '../Footerp'
import {Simuladores} from '../Simuladores'
import foto1 from "./simulador.png";
import foto2 from "./notariales.png";
import foto3 from "./hipotecario.png";
import foto4 from "./bancario.png";
import './Simular.css'


function Simular() {
  
    return (
        <React.Fragment>

            <div className="contesimulador">
                <Simuladores
                    simulador="arrendamiento En Construccion"
                    foto={foto1}
                />
                <Simuladores
                    simulador="gastos notariales En Construccion"
                    foto={foto2}
                />
                
                <Simuladores
                    simulador="hipotecas En Construccion"
                    foto={foto3}
                />
                
                <Simuladores
                    simulador="credito bancario "
                    foto={foto4}
                    vinculo="https://www.bancodeoccidente.com.co/solicitarcredito/#/"
                />
                
            </div>
            <Footerp/>
        </React.Fragment>
    )
}

export{Simular}
import React from "react";
import venta from "./ventas.png";
import ventasm from "./ventasm.png";
import ventasl from "./ventasl.png";
import {VentasInfo} from "./VentasInfo"
import  downd  from  './downd.png'

function VentaS() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }

  return (
    <React.Fragment>
      <div className="contenedorservicios">
        {/* ventas */}
        {/* eslint-disable-next-line */}
        <a name="ventass" />
        <div>
          {/* imagen */}
          <picture>
            <source media="(min-width:950px)" srcSet={ventasl} />
            <source media="(min-width:640px)" srcSet={ventasm} />
            <img src={venta} alt="ventas" className="img-ventas" />
          </picture>
          {/* titulo */}
          <h3 
          
          className="titulo-ventas"
          onClick={onActive}
          >Ventas <img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>

          <div className={active? "activete" : "non-activete" }>
            <VentasInfo/>
          </div>



        </div>
      </div>
    </React.Fragment>
  );
}

export {VentaS}

import React from "react";



function ValorInfo() {
    return (
      <React.Fragment>
        
          {/* parrafo */}
          <p className="parrafos">
            Orientación al cliente: Centramos nuestros esfuerzos en la
            satisfacción del cliente, aportando soluciones competitivas y de
            calidad generando credibilidad y confianza
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Conducta ética: Actuamos con profesionalismo, integridad moral,
            lealtad y respeto a las personas.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Transparencia: Actuamos coherentemente con la información, siendo
            abierta , clara , oportuna y verificable.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Trabajo en equipo: Fomentamos la participación de todos para lograr un
            objetivo común, compartiendo información y conocimientos. Establecemos
            metas y evaluación de la gestión por resultados.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Innovación: Promovemos mejoras continuas e innovamos para alcanzar la
            máxima calidad en nuestros procesos a partir de criterios de
            rentabilidad.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Formación Integral: Capacitación y actualización permanente de
            nuestros colaboradores en la legislación, normas y entidades
            gubernamentales que rigen el sector inmobiliario
          </p>
          <br />

    </React.Fragment>
  );
}

export { ValorInfo };
import React from "react";
import financiera from "./financiera.png";
import {FinancieraInfo} from "./FinancieraInfo"
import  downd  from  './downd.png'

function Financiera() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }
  return (
    <React.Fragment>
      <div className="contenedorservicios">
        {/* eslint-disable-next-line */}
        <a name="financiera" />
        <div>
          {/* imagen */}
          <img src={financiera} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas" onClick={onActive}>
            Asesorías contables, Financieras, tributarias<img src={downd} className={active? "downd-rote" : "downd" }  alt=""/>
          </h3>
          <div className={active? "activete" : "non-activete" }>
            <FinancieraInfo/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export { Financiera };

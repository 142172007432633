import React, {useRef, useEffect} from "react";
import './Slider.css'
import img1 from './img/img1.jpg'
import img2 from './img/img2.jpg'
import img3 from './img/img3.jpg'
import img4 from './img/img4.jpg'
import img5 from './img/img5.jpg'





function Slider() {
    const slideshow = useRef(null)
    const siguiente = () => {
        if (slideshow.current.children.length > 0){
            
            const primerElemento = slideshow.current.children[0]
            console.log(primerElemento)
            slideshow.current.style.transition = `2000ms ease-out all`
            const tamaslide = slideshow.current.children[0].offsetWidth
            slideshow.current.style.transform = `translateX(-${tamaslide}px) `
            const transition = () => {
                
                slideshow.current.style.transition = 'none'
                slideshow.current.style.transform = `translateX(0)`
                slideshow.current.appendChild(primerElemento)
            }
            slideshow.current.addEventListener('transitionend', transition)

            
        }
    }
    useEffect(() => {
        setInterval(() => {
            siguiente()
        },9000)
    },[])

    // hacer como en los simuladores el sistemA DE BUSQUEDA FILTRAR Y RENDERIZAR CON EL BOTON 
    return (
        <React.Fragment>
            <div className="contenedorPrincipal">
                <div className="slideshow" ref={slideshow}>
                    <div className="slide">
                        <img className="sl" src={img1} alt=""></img>
                    </div>
                    <div className="slide">
                        <img className="sl" src={img2} alt=""></img>
                    </div>
                    <div className="slide">
                        <img className="sl" src={img3} alt=""></img>
                    </div>
                    <div className="slide">
                        <img className="sl" src={img4} alt=""></img>
                    </div>
                    <div className="slide">
                        <img className="sl" src={img5} alt=""></img>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export { Slider }
import React from 'react';
import './InfoInmo.css'


function InfoInmo () {
    return (
        <div className='container-info'> 
            <h3 className ='titulo-jjc'>JJC Inmobiliaria</h3>
            <p className='texto'>
            JJC Inmobiliaria es un mundo de posibilidades ágiles y oportunas, en el cual encontraras tu vivienda, tendrás asesoría y estarás en las mejores manos.
            </p>
        </div>

    )

}



export {InfoInmo};

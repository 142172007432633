import React from "react";
import flecha from "./flecha.png";

function JuridicaInfo() {
  return (
    <React.Fragment>
          {/* parrafo */}
          <p className="parrafo">
            Asesoramos legalmente con expertos abogados especializados en las
            diferentes ramas del derecho.
          </p>
          <br />
          {/* subtitulos */}
          <h4 className="subtitulo">DERECHO CIVIL  </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Pertenencias – Posesión de Inmuebles.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Ejecutivos.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Hipotecarios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Divisorios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos de Enriquecimiento sin Justa causa.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Verbales.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos Monitorios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Procesos de Insolvencia</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">DERECHO DE FAMILIA  </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Sucesiones: Liquidación Herencia</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Venta Derechos Herenciales.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Alimentos: Fijación y/o Cobro cuota Alimentaria</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Unión Marital de Hecho: Convivencia entre compañeros.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Divorcios.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Interdicciones.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Muerte Presunta.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Usufructos.</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">PENSIONES  </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Sobrevivientes.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Vejez o Jubilación.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Invalidez.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Gracia.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Indemnización Sustitutiva.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Devolución de saldos.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Incrementos por Personas a cargo.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Traslado Régimen.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Auxilio Funerario</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Pago a Herederos.</li>
            <br />
          </ul>
          {/* subtitulos */}
          <h4 className="subtitulo">OTROS </h4>
          <br />
          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Prestaciones Laborales.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Seguridad Social Responsabilidad por Daños</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Negligencia Médica.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Fideicomisos Civiles.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Derechos de Petición.</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Tutelas.</li>
            <br />
          </ul>
    </React.Fragment>
  );
}

export { JuridicaInfo };
import React from 'react';
import './Button.css'


function Buttone (props) {
    const{contenido } =props
    return (
        
            
            <button type="button" className='buttonPri button-galeria'>
            {contenido}
            </button>
      
        
    )
}

export {Buttone};

import React from 'react';
import './Button.css'
import {
    Link
  } from "react-router-dom";

function Buttonb (props) {
    const{ruta , contenido,} =props
    return (
        <Link to={ruta}>
            
            <button type="button" className='buttonb button-galeria'>
            {contenido}
            </button>
        </Link>
        
    )
}

export {Buttonb};

import React, { useState } from 'react';
import "./InfoCasa.css"
import habitacion from './Habitaciones.png'
import bano from './Banos.png'
import metros from './metros.png'
import garage from './garage.png'
import { useParams } from 'react-router';
import ImageGallery from 'react-image-gallery';
import axios from 'axios';
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import TabCasa from './TabCasa';
import Map from './Map';




ChartJS.register(ArcElement, Tooltip, Legend);
function formatNumber(number) {
    return new Intl.NumberFormat('en-US', {


    }).format(number)
}





function InfoCasa() {
    const { id } = useParams()

    const [loading, setLoading] = useState(true);
    const [ventalist, setVenta] = React.useState([])
    const [simuladorlist, setSimuladorlist] = useState([])
    const [imagenes, setImagen] = React.useState();
    const [asesores, setAsesores] = useState();




    React.useEffect(() => {

        axios.get(`api/inmueble_ventaw/${id}`).then(res => {
            console.log(res.data.inmueble_venta)

            if (res.status === 200) {
                setVenta(res.data.inmueble_venta)
            }
            setLoading(false)

        })

        axios.get(`api/asesor`).then(res => {
            console.log(res.data.asesor);

            if (res.status === 200) {
                setAsesores(res.data.asesor)
            }
            setLoading(false)
        })

        axios.get(`api/imagenes`).then(res => {
            
      
            if (res.status === 200) {
            setImagen(res.data.imagen)  
    
       
     
        }
           
            setLoading(false)
      
          })
          

        axios.get('api/simuladores-vista').then(res => {
            
            if (res.status === 200) {
                setSimuladorlist(res.data.simuladores)
            }
            setLoading(false)

        })
        
        
        // eslint-disable-next-line 
    }, []);
   
    let asesorCasa = asesores || [];

    let ventaAsesor = ventalist || [];

    let asesor = asesorCasa.filter(asesor => asesor.nombre === ventaAsesor.asesor);

     console.log(asesor);
    
    let imgli = imagenes || [];
    let imageneslist = imgli.filter(x=> x.inmueble_id === id)
   
    if (loading) {
        return <h2>Cargando...</h2>
    }
    else {

    }
    let galerias = []
    
    if (imageneslist !== undefined) {
        galerias = imageneslist.map(x => {
            return {
                 original: `http://loginjjc.test/${x.imagen}`,
                 thumbnail: `http://loginjjc.test/${x.imagen}`,
                 originalHeight: '400'
             }
    
         }, [])
         
     } else {
         console.log('no hay imagenes')
     }

    let estrato = ventalist.estrato

    console.log(estrato)
    let acueducto = `simuladores.mensuales_${estrato}_acueducto`
    let energia = `simuladores.mensuales_${estrato}_energia`
    let gas = `simuladores.mensuales_${estrato}_gas`
    let internet = `simuladores.mensuales_${estrato}_internet`


    // eslint-disable-next-line 
    let estratosacueducto = simuladorlist.map(simuladores => eval(acueducto));
    // eslint-disable-next-line 
    let estratosenergia = simuladorlist.map(simuladores => eval(energia));
    // eslint-disable-next-line 
    let estratosgas = simuladorlist.map(simuladores => eval(gas));
    // eslint-disable-next-line 
    let estratosinternet = simuladorlist.map(simuladores => eval(internet));
    // eslint-disable-next-line 
    let administracion = ventalist.administracion
    // eslint-disable-next-line 


    let total = parseInt(estratosacueducto) + parseInt(estratosenergia) + parseInt(estratosgas) + parseInt(estratosinternet) + parseInt(administracion)
    
    
    
    
   
    
        
    
        

    

    let galeria = 
    [
        {
            original: `http://loginjjc.test/${ventalist.image}`,
            originalHeight: '400'
        },
        // {
        //     original: `http://loginjjc.test/${imagenesb.imagen}`,
        //     originalHeight: '400'
        // }
    ]


    // console.log(total)



    return (
        <React.Fragment>

            <div className='cambiosd'>
                <div className="container1">
                    <ImageGallery items={galeria} showNav={false} showFullscreenButton={false} showPlayButton={false}/>
                </div>
                <div className="container2">
                    <ImageGallery items={galerias} showFullscreenButton={false}/>
                </div>



                <div className="contain-infocasa">
                    <h3 className='valorcasa'>{ventalist.tipo_inmueble}</h3>
                    <p className='localizacioncasa'>{ventalist.departamento}, {ventalist.barrio}</p>
                    <h3 className='valorcasa'>{`$${formatNumber(ventalist.valor_venta)}`}</h3>
                    <p className='localizacioncasa'>Código: {ventalist.id }</p>
                    <h3 className='valorcasa'>Informacion</h3>
                    <p className='localizacioncasa'>{ventalist.descripcion}</p>
                    <div className='contenedor-ico'>
                        <p className='habitaciones'><img src={habitacion} alt='ico' className='ico-hab' /> {ventalist.alcobas} Hab</p>
                        <p className='baños'><img src={bano} alt='ico' className='ico-baño' />{ventalist.baños} baños</p>
                        <p className='metros'><img src={metros} alt='ico' className='ico-metros' /> {ventalist.area_lote}m²</p>
                    </div>
                    <div className='contenedor-icogarage'>
                        <p className='garaj'><img src={garage} alt='ico' className='ico-garaje' /> {ventalist.numero_garajes} Garaje/s</p>
                    </div>
                </div>
            </div>



            <div className='tab4' style={{ marginTop: '80px', margin: '80px', display: 'flex', alignItems: 'center' }}>
                <TabCasa  ></TabCasa>
            </div>

            <div style={{ marginTop: '60px', margin: '17px', display: 'flex', alignItems: 'center' }}>
                <Map/>
            </div>


        </React.Fragment>
    )
}

export { InfoCasa }
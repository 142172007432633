import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ADMIN from './ADMIN.png'
import luz from './luz.png'
import inernet from './internet.png'
import gase from './gas.png'
import acue from './acue.png'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { useParams } from 'react-router';



function formatNumber(number) {
  return new Intl.NumberFormat('en-US', {


  }).format(number)
}

function TabPanel(props) {




  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabCasa() {

  const { id } = useParams()

  const [loading, setLoading] = React.useState(true);
  const [ventalist, setVenta] = React.useState([])

  const [simuladorlist, setSimuladorlist] = React.useState([])
  const [value, setValue] = React.useState(0);



  React.useEffect(() => {

    axios.get(`api/inmueble_ventaw/${id}`).then(res => {
      console.log(res.data.inmueble_venta);

      if (res.status === 200) {
        setVenta(res.data.inmueble_venta)
      }
      setLoading(false)

    })
   


    axios.get('api/simuladores-vista').then(res => {
      console.log(res.data.simuladores);
      if (res.status === 200) {
        setSimuladorlist(res.data.simuladores)
      }
      setLoading(false)

    })


// eslint-disable-next-line 
  }, []);

  if (loading) {
    return <h2>Cargando...</h2>
  }
  else {

  }

  let estrato = ventalist.estrato

 
  let acueducto = `simuladores.mensuales_${estrato}_acueducto`
  let energia = `simuladores.mensuales_${estrato}_energia`
  let gas = `simuladores.mensuales_${estrato}_gas`
  let internet = `simuladores.mensuales_${estrato}_internet`


  // eslint-disable-next-line 
  let estratosacueducto = simuladorlist.map(simuladores => eval(acueducto));
  // eslint-disable-next-line 
  let estratosenergia = simuladorlist.map(simuladores => eval(energia));
  // eslint-disable-next-line 
  let estratosgas = simuladorlist.map(simuladores => eval(gas));
  // eslint-disable-next-line 
  let estratosinternet = simuladorlist.map(simuladores => eval(internet));
  // eslint-disable-next-line 
  let administracion = ventalist.administracion


  let total = parseInt(estratosacueducto) + parseInt(estratosenergia) + parseInt(estratosgas) + parseInt(estratosinternet) + parseInt(administracion)

  let data = {
    labels: ['Administracion $', 'Acueducto $', 'Energia $', 'Gas $', 'Internet $'],
    datasets: [
      {
        label: ' # of Votes',
        data: [administracion, estratosacueducto, estratosenergia, estratosgas, estratosinternet],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',

        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',

        ],
        borderWidth: 1,
      }
    ]
  }


  console.log(total)













  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-around' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
          <Tab label="Gastos mensuales" {...a11yProps(0)} />
          <Tab label="Informacion detallada" {...a11yProps(1)} />
          <Tab label="Contacto" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="hola">
          <h3 className='gastos'>Gastos mensuales</h3>
          <div id='pastel'>
            <Doughnut data={data} className='past' />
          </div>
          <h3 className='total'>{`Total $${formatNumber(total)}`}</h3>
          <div className=" flex">
            <p className='localizacioncasa infogastos flex'><img src={ADMIN} alt='ico' className='ico-gastos' />  Administracion   <span className='valorgastos'>{` $${formatNumber(administracion)}`}</span></p>
            <p className='localizacioncasa infogastos flex'><img src={luz} alt='ico' className='ico-gastos' /> Energia <span className='valorgastos'>{` $${formatNumber(estratosenergia)}`}</span></p>
          </div>
          <div className=" flex">
            <p className='localizacioncasa infogastos flex'><img src={gase} alt='ico' className='ico-gastos' /> Gas <span className='valorgastos'>{` $${formatNumber(estratosgas)}`}</span></p>
            <p className='localizacioncasa infogastos flex'><img src={inernet} alt='ico' className='ico-gastos' /> Internet <span className='valorgastos'>{` $${formatNumber(estratosinternet)}`}</span></p>
          </div>
          <div className=" flex">
            <p className='localizacioncasa infogastos flex'><img src={acue} alt='ico' className='ico-gastos' /> Acueducto <span className='valorgastos'>{` $${formatNumber(estratosacueducto)}`}</span></p>
          </div>
        </div>

      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="hola">
        <h3 className='gastos'>Informacion detallada</h3>
          <div className=" flex">
            <p className='localizacioncasa infogastos '> Sala-comedor   <span className='valorgastos'>{ventalist.sala_comedor}</span></p>
            <p className='localizacioncasa infogastos '> Sala <span className='valorgastos'>{ventalist.sala}</span></p>
            <p className='localizacioncasa infogastos '> Comedor <span className='valorgastos'>{ventalist.comedor}</span></p>
          </div>
          <div className=" flex">
            <p className='localizacioncasa infogastos '> Estudio   <span className='valorgastos'>{ventalist.estudio}</span></p>
            <p className='localizacioncasa infogastos '> Clase de parqueadero <span className='valorgastos'>{ventalist.tipo_garage}</span></p>
            <p className='localizacioncasa infogastos '> Pisos <span className='valorgastos'>{ventalist.pisos}</span></p>
          </div>
          <div className=" flex">

          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="hola">
        <h3 className='gastos'>Contacto</h3>
          <div className=" flex">
            <p className='localizacioncasa infogastos '> sector   <span className='valorgastos'>{ventalist.sector}</span></p>
            <p className='localizacioncasa infogastos '> Asesor <span className='valorgastos'>{ventalist.asesor}</span></p>
            <p className='localizacioncasa infogastos '>  <span className='valorgastos'>{ventalist.comedor}</span></p>
          </div>
        </div>

      </TabPanel>
    </Box>
  );
}

import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";
import  down  from  './down.png'
import  up  from  './up.png'

function MenuOrganizacion() {
  return (
    <React.Fragment>
      <div className="menucontainer-g">
        <ul className="menu-pri">
          <li className="f">
          {/* // eslint-disable-next-line */}
            <Link to="/menu">Organizacion<img src={up} className="down" alt=""/></Link>
            <ul className="s">
              <li>
                <Link to="/sobrenosotros">Sobre nosotros</Link>
              </li>
              <li>
                <Link to="/mision">Mision</Link>
              </li>
              <li>
                <Link to="/vision">Visión</Link>
              </li>
              <li>
                <Link to="/valor">Valores</Link>
              </li>
            </ul>
            </li>
          <li className="f">
            <Link to="/menuservicios">Servicios<img src={down} className="down" alt=""/></Link>
          </li>
          <li className="f">
            <Link to="/menusimuladores">Simuladores<img src={down} className="down" alt=""/></Link>
          </li>
        
        </ul>
      </div>
    </React.Fragment>
  );
}

export {MenuOrganizacion };

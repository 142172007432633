import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import {Header} from '../Header'
import {Home} from '../Home'
import {Busqueda} from '../Busqueda'
import {Simular} from '../Simular'
import {Casas} from '../Casas'
import {Menu} from '../Menu'
import {MenuServicios} from '../Menu/MenuServisios'
import {MenuOrganizacion} from '../Menu/MenuOrganizacion'
import {MenuSimuladores} from '../Menu/MenuSimuladores'
import {HeaderMenu} from '../HeaderMenu'
import {SobreNosotros} from '../SobreNosotros'
import {Footerp} from '../Footerp'
import {EncabezadoSecciones} from '../EncabezadoSecciones'
import {VentaS} from '../servisiosSeparados/VentaS'
import {Arriendos} from '../servisiosSeparados/Arriendos'
import {Avaluos} from '../servisiosSeparados/Avaluos'
import {Prestamos} from '../servisiosSeparados/Prestamos'
import {Juridica} from '../servisiosSeparados/Juridica'
import {Financiera} from '../servisiosSeparados/Financiera'
import {Arquitectura} from '../servisiosSeparados/Arquitectura'
import {SobreNosotrosSeparado} from '../SobreNosotrosSeparado/SobreNosotrosSeparado'
import {Mision} from '../SobreNosotrosSeparado/Mision'
import {Vision} from '../SobreNosotrosSeparado/Vision'
import {Valor} from '../SobreNosotrosSeparado/Valor'
import { Servicios } from '../Servicios';
import {Bot} from "../servisiosSeparados/Bot"
import SimuladoreArrendamiento from '../Simuladores/SimuladorArrendamiento';
import SimuladoreHipoteca from '../Simuladores/SimuladorHipoteca';
import SimuladoreVenta from '../Simuladores/SimuladorVenta';
import axios from 'axios';
import Whatsapp from '../Whatsapp'
import { CasasArriendo } from '../Casas/CasasArriendo';
import { Bcasas } from '../Busqueda/Bcasas';
import { Blotes } from '../Busqueda/Blotes';
import { Bapartamentos } from '../Busqueda/Bapartamentos';
import { Bfuera } from '../Busqueda/Bfuera';
import { Bnuevos } from '../Busqueda/Bpronuevos';



axios.defaults.baseURL="https://api.jjcinmobiliaria.com.co/"
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials= true;
function App() {
  
  return (
    <React.Fragment>
      <Router>
        
        <Switch>
          <Route path='/' exact>
              <Header/>
              <Home/>
          </Route>
          <Route path='/busqueda_casas' >
              <Header/>
              <EncabezadoSecciones
                titulo='Resultados'/>
              <Bcasas/>
              <Footerp/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
          </Route>
          <Route path='/busqueda_lotes' >
              <Header/>
              <EncabezadoSecciones
                titulo='Resultados'/>
              <Blotes/>
              <Footerp/>
            <div style={{position: 'fixed', bottom: 0,right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
          </Route>
          <Route path='/busqueda_apartamentos' >
              <Header/>
              <EncabezadoSecciones
                titulo='Resultados'/>
              <Bapartamentos/>
              <Footerp/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
          </Route>
          <Route path='/busqueda_fuera' >
              <Header/>
              <EncabezadoSecciones
                titulo='Resultados'/>
              <Bfuera/>
              <Footerp/>
            <div style={{position: 'fixed', bottom: 0,right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
          </Route>
          <Route path='/busqueda_nuevos' >
              <Header/>
              <EncabezadoSecciones
                titulo='Resultados'/>
              <Bnuevos/>
              <Footerp/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
          </Route>
          <Route path={'/ventas/:id'} >
              <Header/>
              <Casas/>
          </Route>
          <Route path='/arriendos/:id' >
              <Header/>
              <CasasArriendo/>
          </Route>
          <Route path='/simuladores' >
              <Header/>
              <Simular/>
          </Route>
          <Route path='/menu' >
            <HeaderMenu/>
            <Menu/>
            <Home/>
          </Route>
          <Route path='/menuservicios' >
            <HeaderMenu/>
            <MenuServicios/>
            <Home/>
          </Route>
          <Route path='/menuorganizacion' >
            <HeaderMenu/>
            <MenuOrganizacion/>
            <Home/>
          </Route>
          <Route path='/menusimuladores' >
            <HeaderMenu/>
            <MenuSimuladores/>
            <Home/>
          </Route>
          <Route path='/organizacion' >
            <Header/>
            <EncabezadoSecciones
            titulo="¿Quiénes Somos?"/>
            <SobreNosotros/>
            <Footerp/>
          </Route>
          <Route path='/servicios' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo="Servicios"/>
            <Servicios/>
            <Footerp/>
          </Route>
          <Route path='/ventas' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <VentaS/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/arriendos' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Arriendos/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/avaluos' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0,right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Avaluos/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/prestamos' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Prestamos/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/juridica' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Juridica/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/financiera' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Financiera/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/arquitectura' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Arquitectura/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/sobrenosotros' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <SobreNosotrosSeparado/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/mision' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Mision/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/vision' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Vision/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/valor' >
            <Header/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
            <EncabezadoSecciones
            titulo=""/>
            <Valor/>
            <Bot/>
            <Footerp/>
          </Route>
          <Route path='/arrendamiento' >
              <Header/>
              <SimuladoreArrendamiento
              />
          </Route>
          <Route path='/GastosNotariales' >
              <Header/>
              <SimuladoreVenta
              />
          </Route>
          <Route path='/GastosHipoteca' >
              <Header/>
              <SimuladoreHipoteca/>
          </Route>
        </Switch>
      </Router>

    </React.Fragment>
  );
}

export default App;

import React from 'react';
import './Button.css'
import {
    Link
  } from "react-router-dom";

function Button (props) {
    const{ruta , contenido,} =props
    return (
        <Link to={ruta}>
            
            <button type="button" className='buttonPri button-galeria'>
            {contenido}
            </button>
        </Link>
        
    )
}

export {Button};

import React from "react";





function Bot() {
  return (
    <React.Fragment>
      <div className="bot">
    
      </div>
    </React.Fragment>
  );
}

export { Bot };

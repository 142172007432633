import React from 'react';
import './EncabezadoSecciones.css'
import logo from './Logo_azul.png'



function EncabezadoSecciones ({titulo}) {
    return(
            
        
        <div className ='encabezado'>
            
            {/* eslint-disable-next-line */}
            <a name={titulo} />
            <img src={logo}alt=''className='logo-encabezado'/>
            <h2 className='titulo-encabezado'>{titulo}</h2>
        </div>
    )

}



export {EncabezadoSecciones};
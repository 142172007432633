import flecha from "./flecha.png";
import React from "react";

function FinancieraInfo() {
  return (
    <React.Fragment>
         {/* parrafo */}
         <p className="parrafo">
            A través de nuestro servicio de consultoría y asesoramiento
            contable, financiero y tributario satisfacemos las necesidades de
            nuestros clientes en materia de:
          </p>
          <br />

          {/* lista */}
          <ul className="parrafo">
            <img className="flecha" src={flecha} alt="" />
            <li>Codificación, contabilización y análisis de operaciones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Elaboración de Estados Financieros. (Balance General, Estado de
              Resultados, Flujo de Efectivo, Estado de Cambio en el Patrimonio,
              Estado de Cambio en la Situación Financiera)
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Elaboración de reportes a entidades de control Impuestos (Renta,
              Retención en la Fuente, IVA, Timbre, GMF. Riqueza, Predial,
              Vehículos, Industria y Comercio)
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Elaboración de declaraciones tributarias Municipal y Nacional
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Liquidación y registro de importaciones</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>
              Revisoría Fiscal – Auditoría financiera Conversión de Estados
              Financieros a Normas internacionales NIIF
            </li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Conciliaciones bancarias</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Análisis de cuentas de Balance</li>
            <br />
            <img className="flecha" src={flecha} alt="" />
            <li>Reportes a socios</li>
            <br />
          </ul>
    </React.Fragment>
  );
}

export { FinancieraInfo };

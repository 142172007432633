import React, { useState } from "react";
import "./Galeria.css";
import { GaleriaVentas } from "../GaleriaVentas";
import habitacion from './Habitaciones.png'
import bano from './Banos.png'
import metros from './metros.png'
import axios from "axios";
import '../GaleriaVentas/GaleriaVentas.css'
import { Button } from '../Button'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { GaleriaArriendos } from "../GaleriaVentas/GaleriaArriendos";
// import {active} from "./logic.js"



function TabPanel(props) {




  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function formatNumber(number) {
  return new Intl.NumberFormat('en-US', {


  }).format(number)
}
function Bfuera(props) {

  const [value, setValue] = React.useState(0);

  const [loading, setLoading] = useState(true);

  const [ventalist, setVenta] = React.useState([])
  const [arriendolist, setArriendo] = React.useState([])



  React.useEffect(() => {


    axios.get('api/inmueble_venta').then(res => {
      console.log(res.data.inmueble_venta);

      if (res.status === 200) {
        setVenta(res.data.inmueble_venta)
      }
      setLoading(false)

    })
    axios.get('api/Arriendos').then(res => {
      console.log(res.data.Arriendos);

      if (res.status === 200) {
        setArriendo(res.data.Arriendos)
      }
      setLoading(false)

    })





  }, []);

  if (loading) {
    return <h2>Cargando...</h2>
  }
  else {

  }
let arriendosdes = arriendolist || [];

let arriendosdestacados = arriendosdes.filter(x => x.vendido < 1 &&  x.departamento !== 'Bogotá D.C.')

let ventasdes = ventalist || [];

let vebntasdestacados = ventasdes.filter(x => x.vendido < 1 &&   x.departamento !== 'Bogotá D.C.')



  let holi = "1"
  let chao = ""
  const onAct = () => {
    holi = "1"
    chao = ""
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-around' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
          <Tab label="Ventas" {...a11yProps(0)} />
          <Tab label="Arriendos" {...a11yProps(1)} />

        </Tabs>
      </Box>

      {/* <ul className="header-galeri">
        <li className={`letras  ${holi && 'letras active'}`}
          onClick={onAct}>

          Ventas

        </li>
        <li className={`letras  ${chao && 'letras active'}`}
          onClick={props.onActivea}>

          Arrendamientos

        </li>
      </ul> */}
      <TabPanel value={value} index={0}>
        <div id="ventas" className="ventas-con-libre bloque">

          {vebntasdestacados.map((venta) => (
            <div id="cardVentas" className="card-ventas">
              <img src={`http://loginjjc.test/${venta.image}`} alt='casa' className='imagen-csa' />
              <h3 className='valor'>{`$${formatNumber(venta.valor_venta)}`}</h3>
              <p className='localizacion'>{(venta.departamento) + ', ' + (venta.barrio)}</p>
              <p className='localizacion'>{'Cod:'+(venta.id)}</p>
              <div className='contenedor-ico'>
                <p className='habitaciones'><img src={habitacion} alt='ico' className='ico-hab' /> {venta.alcobas} Hab</p>
                <p className='baños'><img src={bano} alt='ico' className='ico-baño' />{venta.baños} baños</p>
                <p className='metros'><img src={metros} alt='ico' className='ico-metros' /> {venta.area_lote}m²</p>
              </div>
              <div className="cont">
                <Button

                  contenido='ver mas'
                  ruta={`/ventas/${venta.id}`}
                />
              </div>
            </div>
          ))}
 
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div id="ventas" className="ventas-con-libre bloque">
          {arriendosdestacados.map((venta) => (
            <div id="cardVentas" className="card-ventas">
              <img src={`http://loginjjc.test/${venta.image}`} alt='casa' className='imagen-csa' />
              <h3 className='valor'>{`$${formatNumber(venta.valor_canon)}`}</h3>
              <p className='localizacion'>{(venta.departamento) + ', ' + (venta.barrio)}</p>
              <p className='localizacion'>{'Cod:'+(venta.id)}</p>
              <div className='contenedor-ico'>
                <p className='habitaciones'><img src={habitacion} alt='ico' className='ico-hab' /> {venta.alcobas} Hab</p>
                <p className='baños'><img src={bano} alt='ico' className='ico-baño' />{venta.baños} baños</p>
                <p className='metros'><img src={metros} alt='ico' className='ico-metros' /> {venta.area_lote}m²</p>
              </div>
              <div className="cont">
                <Button

                  contenido='ver mas'
                  ruta={`/arriendos/${venta.id}`}
                />
              </div>
              </div>
              ))}

   
              </div>
            </TabPanel>


    </Box>
        );
}

        export {Bfuera};

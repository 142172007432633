import React from "react";
import juridico from "./juridico.png";
import {JuridicaInfo} from "./JuridicaInfo"
import  downd  from  './downd.png'

function Juridica() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }
  return (
    <React.Fragment>
      <div className="contenedorservicios">
        {/* eslint-disable-next-line */}
        <a name="juridica" />
        <div>
          {/* imagen */}
          <img src={juridico} alt="ventas" className="img-ventas" />
          {/* titulo */}
          <h3 className="titulo-ventas" onClick={onActive}>Asesoría jurídica<img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>
          <div className={active? "activete" : "non-activete" }>
              <JuridicaInfo/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export { Juridica };


import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



function formatNumber(number) {
  return new Intl.NumberFormat().format(number)
}



export default function SimuladoreVenta() {
  const [regtrivvend, setRegtrivvend] = useState('');
  const [consthiposincua, setConsthiposincua] = useState();
  const [cancelhiposincua, setCancelhiposincua] = useState();
  const [constiafecvivifam, setConstiafecvivifam] = useState();
  const [cancelafecvivifam, setCancelafecvivifam] = useState();
  const [constipatrifam, setConstipatrifam] = useState();
  const [cancelpatrifam, setCancelpatrifam] = useState();
  const [loading, setLoading] = useState(true);
  const [simuladorlist, setSimuladorlist] = useState([])
  const [nocalc, setSimular] = useState("")

  const [valorhelp, setValorhelp] = useState("")
  const [hipohelp, setHipohelp] = useState("")
  const [hipohelpcua, setHipohelpcua] = useState("")

  useEffect(() => {
    axios.get('api/simuladores-vista').then(res => {
      console.log(res.data.simuladores);
      if (res.status === 200) {
        setSimuladorlist(res.data.simuladores)
      }
      setLoading(false)

    })

  }, []);
  console.log(simuladorlist)


  if (loading) {
    return <h2>Cargando...</h2>
  }
  else {

  }




  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      valor: data.get('valor'),
      cancel_hipo_con_cuantia: data.get('cancel_hipo_con_cuantia'),
      consti_hipo_concuantia: data.get('consti_hipo_concuantia'),
    });


    let registro150 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 150
    let registro260 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 260
    let registro385 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 385
    let registro386 = simuladorlist.map(simuladores => simuladores.notaria_smmlv) * 386

    let valor = parseInt(data.get('valor'));
    let cancel_hipo_con_cuantia = data.get('cancel_hipo_con_cuantia');
    let consti_hipo_concuantia = data.get('consti_hipo_concuantia');

    let gastos_escrituracion = valor * simuladorlist.map(simuladores => simuladores.gn_escrituracion) / 100 / 2
    let retencion_fuente_sobre_verde = valor * simuladorlist.map(simuladores => simuladores.gn_rte_fte_venta) / 100
    let otros_gastos = simuladorlist.map(simuladores => simuladores.gn_valor_copias)
    let cancela_hipo_con_cuantia = cancel_hipo_con_cuantia * simuladorlist.map(simuladores => simuladores.gn_cancel_hipo_con_cuantia) / 1000
    let const_hipo = consti_hipo_concuantia * simuladorlist.map(simuladores => simuladores.gn_consti_hipo_cuantia) / 100
    let cancel_hipo_sincua = simuladorlist.map(simuladores => simuladores.gn_cancel_hipo_sin_cuantia)
    let const_hipo_sincua = simuladorlist.map(simuladores => simuladores.gn_consti_hipo_sin_cuantia)
    let conti_afect_familiar = simuladorlist.map(simuladores => simuladores.gn_consti_afecta_familiar)
    let const_patri_fami = simuladorlist.map(simuladores => simuladores.gn_consti_patri_familiar)
    let cancel_patri_fami = simuladorlist.map(simuladores => simuladores.gn_cancel_patri_familiar)
    let cancel_afecta_vivenda_familiar = simuladorlist.map(simuladores => simuladores.gn_cancel_afect_familiar)
    let gn_iva_servicios_notariales = valor * simuladorlist.map(simuladores => simuladores.gn_iva_servicios_notariales) / 100 / 2

    let beneficencia = valor * simuladorlist.map(simuladores => simuladores.notaria_beneficencia) / 100
    let registro = ''
    if (valor < registro150) {
      registro = valor * simuladorlist.map(simuladores => simuladores.notaria_actos_150) / 1000
    }
    else if (valor < registro260) {
      registro = valor * simuladorlist.map(simuladores => simuladores.notaria_actos_260) / 100
    }
    else if (valor < registro385) {
      registro = valor * simuladorlist.map(simuladores => simuladores.notaria_actos_385) / 100
    }
    else if (valor > registro386) {
      registro = valor * simuladorlist.map(simuladores => simuladores.notaria_actos_386) / 100
    }

    // let registro_escri_hipo = valor * simuladorlist.map(simuladores => simuladores.notaria_registro_hipo) / 1000
    // let registro_afectacion_vivienda = simuladorlist.map(simuladores => simuladores.notaria_registro_afectacion)

    function createData(detalle, vendedor, comprador) {
      return { detalle, vendedor, comprador };
    }

    let vendedores = parseInt(gastos_escrituracion) + parseInt(otros_gastos) + parseInt(gn_iva_servicios_notariales)
    let comprador = parseInt(gastos_escrituracion) + parseInt(otros_gastos) + parseInt(gn_iva_servicios_notariales) + parseInt(beneficencia) + parseInt(registro)
    let rows = [
      createData("Gastos de escrituracion", `$ ${formatNumber(gastos_escrituracion)}`, `$ ${formatNumber(gastos_escrituracion)}`),
      createData("otros gastos", `$ ${formatNumber(otros_gastos)}`, `$ ${formatNumber(otros_gastos)}`),
      createData("Iva Notaria 19 %", `$ ${formatNumber(gn_iva_servicios_notariales)}`, `$ ${formatNumber(gn_iva_servicios_notariales)}`),
      createData("Beneficencia escritura pública", '', `$ ${formatNumber(beneficencia)}`),
      createData("Registro escritura pública", '', `$ ${formatNumber(registro)}`),
      // createData("retencion en la fuente sobre venta", `$ ${formatNumber(retencion_fuente_sobre_verde)}`, ''),
      // createData("Cancelación hipoteca con cuantía", `$ ${formatNumber(cancela_hipo_con_cuantia)}`, ''),
      // createData("Constitución hipoteca", '', `$ ${formatNumber(const_hipo)}`),
      // createData("Constitución hipoteca sin cuantia", '', `$ ${formatNumber(cancel_hipo_sincua)}`),
      // createData("Cancelación hipoteca sin cuantía", `$ ${formatNumber(cancel_hipo_sincua)}`, ''),
      // createData("Constitución Afectación familiar", '', `$ ${formatNumber(conti_afect_familiar)}`),
      // createData("Cancelación Afectación familiar", '', `$ ${formatNumber(cancel_afecta_vivenda_familiar)}`),
      // createData("Constitución patrimonio familia", `$ ${formatNumber(const_patri_fami)}`, ''),
      // createData("Registro escritura hipoteca con cuantía", '', `$ ${formatNumber(registro_escri_hipo)}`),
      // createData("Registro afectación vivienda familiar", '', `$ ${formatNumber(registro_afectacion_vivienda)}`),
      // createData("Total", `$ ${formatNumber(vendedores)}`, `$ ${formatNumber(comprador)}`),
    ]




    if (data.get('regimen_tributario_vendedor') === '10') {

      vendedores = vendedores + parseInt(retencion_fuente_sobre_verde)

      rows.push(
        createData("retencion en la fuente sobre venta", `$ ${formatNumber(retencion_fuente_sobre_verde)}`, ''),
        
      )
    }else{}

    if (data.get('comstitucion_hipo_sin_cuantia') === '10') {

      comprador = comprador + parseInt(const_hipo_sincua)

      rows.push(
        createData("Constitución hipoteca sin cuantia", '', `$ ${formatNumber(const_hipo_sincua)}`),
      )
    } else{}

    if (data.get('cancelacion_hipoteca_sin_cuantia') === '10') {

      vendedores = vendedores + parseInt(cancel_hipo_sincua)

      rows.push(
        createData("Cancelación hipoteca sin cuantía", `$ ${formatNumber(cancel_hipo_sincua)}`, ''),
      )
     } else{}

     if (data.get('constitucion_afectacion_vivienda_familiar') === '10') {

      comprador = comprador + parseInt(conti_afect_familiar)

      rows.push(
        createData("Constitución Afectación familiar", '', `$ ${formatNumber(conti_afect_familiar)}`),
      )
     } else{}

     if (data.get('cancelacion_afectacion_vivienda_familiar') === '10') {

      comprador = comprador + parseInt(cancel_afecta_vivenda_familiar)

      rows.push(
        createData("Cancelación Afectación familiar", '', `$ ${formatNumber(cancel_afecta_vivenda_familiar)}`),
      )
     } else{}

     if (data.get('constitucion_patrimonio_familiar') === '10') {

      vendedores = vendedores + parseInt(const_patri_fami)

      rows.push(
        createData("Constitución patrimonio familia", `$ ${formatNumber(const_patri_fami)}`, ''),
      )
     } else{}

     if (data.get('cancelacion_patrimonio_familiar') === '10') {

      vendedores = vendedores + parseInt(cancel_patri_fami)

      rows.push(
        createData("Cancelacion patrimonio familia", `$ ${formatNumber(cancel_patri_fami)}`, ''),
      )
     } else{}

     if (parseInt (cancel_hipo_con_cuantia) > 0) {

      vendedores = vendedores + parseInt(cancel_patri_fami)

      rows.push(
        createData("Cancelación hipoteca con cuantía", `$ ${formatNumber(cancela_hipo_con_cuantia)}`, '')
      )
     } else{}

     if (parseInt (consti_hipo_concuantia) > 0) {

      comprador = comprador + parseInt(cancel_patri_fami)

      rows.push(
        createData("Constitución hipoteca con cuantia", '', `$ ${formatNumber(const_hipo)}`),
      )
     } else{}

     if (true) {


      rows.push(
        createData("Total", `$ ${formatNumber(vendedores)}`, `$ ${formatNumber(comprador)}`),
      )
     } else{}
    


    if (data.get('valor') > 0) {
      setSimular(
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Detalles</TableCell>
                <TableCell align="right">Vendedor</TableCell>
                <TableCell align="right">Comprador</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.detalle}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.detalle}
                  </TableCell>
                  <TableCell align="right" >{row.vendedor}</TableCell>
                  <TableCell align="right">{row.comprador}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else {
      setSimular(
        <>
          <Typography sx={{ m: 2 }} margin="6" variant="subtitle1" component="div" color="red">
            el campo de "Valor a escriturar" es requerido
          </Typography>

        </>
      )
    }
  }








  const handleChangepri = (event) => {
    setRegtrivvend(event.target.value);
  };
  const handleChangesec = (event) => {
    setConsthiposincua(event.target.value);
  };
  const handleChangepriter = (event) => {
    setCancelhiposincua(event.target.value);
  };
  const handleChangepricuar = (event) => {
    setConstiafecvivifam(event.target.value);
  };
  const handleChangepriquin = (event) => {
    setCancelafecvivifam(event.target.value);
  };
  const handleChangeprisex = (event) => {
    setConstipatrifam(event.target.value);
  };
  const handleChangeprisep = (event) => {
    setCancelpatrifam(event.target.value);
  };

  const handleChangehv = (event) => {
    setValorhelp(formatNumber(event.target.value));
  }; const handleChangehhc = (event) => {
    setHipohelp(formatNumber(event.target.value));
  }; const handleChangehcc = (event) => {
    setHipohelpcua(formatNumber(event.target.value));
  };


  return (
    <Box
      component="form" onSubmit={handleSubmit} noValidate
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Card sx={{ maxWidth: 1275 }}>
        <CardContent>
          <Box sx={{ mt: 1 }}>
            <Typography variant="h5" component="div">
              Simulador de Gastos notariales
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              A continuación encontrara un simulador de gastos notariales para que tenga una idea aproximada de los valores, esta herramienta tiene como objetivo facilitar unos valores aproximados, recomendamos obtener una liquidación directamente en la notaria, la inmobiliaria no asume responsabilidad alguna si llegasen a existir variaciones en los valores aquí relacionados.
            </Typography>
            <TextField
              label="Valor a escriturar"
              id="outlined-start-adornment"
              name='valor'
              sx={{ m: 1, width: '25ch' }}
              helperText={`$ ${valorhelp}`}
              onChange={handleChangehv}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={regtrivvend}
                label="Age"
                name="regimen_tributario_vendedor"
                onChange={handleChangepri}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Simple</MenuItem>
                <MenuItem value={20}>Común</MenuItem>
                <MenuItem value={30}>Gran contribuyente</MenuItem>

              </Select>
              <FormHelperText>Regimen tributario Vendedor</FormHelperText>
            </FormControl>
            <TextField
              label="Cancelacion Hipoteca con cuantia"
              id="outlined-start-adornment"
              name="cancel_hipo_con_cuantia"
              helperText={`$ ${hipohelp}`}
              onChange={handleChangehhc}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              label="Constitución Hipoteca con cuantia"
              id="outlined-start-adornment"
              name='consti_hipo_concuantia'
              sx={{ m: 1, width: '25ch' }}
              helperText={`$ ${hipohelpcua}`}
              onChange={handleChangehcc}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={consthiposincua}
                label="Regimen tributario propietario"
                name="comstitucion_hipo_sin_cuantia"
                onChange={handleChangesec}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Si</MenuItem>
                <MenuItem value={20}>No</MenuItem>


              </Select>
              <FormHelperText>Constitución hipoteca sin cuantía</FormHelperText>
            </FormControl>
          </Box>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={cancelhiposincua}
              label="Regimen tributario propietario"
              name="cancelacion_hipoteca_sin_cuantia"
              onChange={handleChangepriter}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Si</MenuItem>
              <MenuItem value={20}>No</MenuItem>


            </Select>
            <FormHelperText>Cancelación hipoteca sin cuantía </FormHelperText>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={constiafecvivifam}
              label="Age"
              name="constitucion_afectacion_vivienda_familiar"
              onChange={handleChangepricuar}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Si</MenuItem>
              <MenuItem value={20}>No</MenuItem>


            </Select>
            <FormHelperText>Constitución afectación vivienda familiar </FormHelperText>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={cancelafecvivifam}
              label="Age"
              name="cancelacion_afectacion_vivienda_familiar"
              onChange={handleChangepriquin}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Si</MenuItem>
              <MenuItem value={20}>No</MenuItem>


            </Select>
            <FormHelperText>Cancelación de afectación Vivienda familiar </FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={constipatrifam}
              label="Age"
              name="constitucion_patrimonio_familiar"
              onChange={handleChangeprisex}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Si</MenuItem>
              <MenuItem value={20}>No</MenuItem>

            </Select>
            <FormHelperText>Constitución patrimonio familia </FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={cancelpatrifam}
              label="Age"
              name="cancelacion_patrimonio_familiar"
              onChange={handleChangeprisep}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Si</MenuItem>
              <MenuItem value={20}>No</MenuItem>

            </Select>
            <FormHelperText>Cancelación patrimonio familia  </FormHelperText>
          </FormControl>

        </CardContent>
        <CardActions>
          <Button type="submit" variant="contained">Calcular</Button>
        </CardActions>
        <span>
          {nocalc}
        </span>
      </Card>
    </Box>
  );
}

import React from "react";
import "./SobreNosotros.css";
import sobren from "./sobren.png";
import sobrem from "./sobrem.png";
import mision from "./mision.png";
import misionm from "./misionm.png";
import vision from "./vision.png";
import visionm from "./visionm.png";
import valor from "./valor.png"
import valorm from "./valorem.png"
import Whatsapp from '../Whatsapp'
function SobreNosotros() {
  return (
    <React.Fragment>
      <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
      <div className="contenedorsobre">
        {/* Arquitectura*/}
        <div>
        {/* eslint-disable-next-line */}
          <a name="sobre"/>
          {/* imagen */}
          <picture>
            <source media="(min-width:640px)" srcSet={sobrem} />
            <img src={sobren} alt="ventas" className="img-venta" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas">Sobre nosotros</h3>
          {/* parrafo */}
          <p className="parrafos">
            Somos una compañía con más de 25 años de experiencia en el sector
            inmobiliario ofreciendo servicios y soluciones efectivas en ventas
            inmuebles, arrendamientos, avalúos, hipotecas, arquitectura y diseño;
            adicionalmente ofrecemos servicios de asesoría en las áreas, contable,
            financiera, tributaria y jurídica las cuales complementan la actividad
            inmobiliaria.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Nuestra trayectoria sumada al desarrollo e implementación de procesos
            de alta calificación, nos ha permitido ser una de las compañías
            líderes en el sector, generando la confianza de nuestros clientes y a
            su vez hemos logrado contribuir al desarrollo Urbanístico del país en
            especial en la ciudad de Bogotá D.C.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Contamos con excelentes y cómodas instalaciones en donde ofrecemos una
            atención personalizada e integral a nuestros clientes, con un equipo
            humano altamente calificado capaz de satisfacer de manera oportuna sus
            requerimientos. Por eso JJC INMOBILIARIA somos un mundo de
            posibilidades con soluciones agiles y oportunas.
          </p>
          <br />
        </div>
        {/* mision*/}
        {/* eslint-disable-next-line */}
        <a name="mision"/>
        <div>
          {/* imagen */}
          <picture>
            <source media="(min-width:640px)" srcSet={misionm} />
            <img src={mision} alt="ventas" className="img-venta" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas">Misión</h3>
          {/* parrafo */}
          <p className="parrafos">
            Brindar de manera integral servicios de asistencia y soporte en la
            gestión inmobiliaria, siendo efectivos y oportunos en todas las etapas
            de nuestra intervención, aplicando los mas altos estándares de
            calidad, con una propuesta de valor que genere la confianza de
            nuestros clientes contribuyendo con ello al desarrollo y
            reconocimiento del sector.
          </p>
          <br />
        </div>
        {/* vision*/}
        {/* eslint-disable-next-line */}
        <a name="vision"/>
        <div>
          {/* imagen */}
          <picture>
            <source media="(min-width:640px)" srcSet={visionm} />
            <img src={vision} alt="ventas" className="img-venta" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas">Visión</h3>
          {/* parrafo */}
          <p className="parrafos">
            Ser líderes en el sector inmobiliario y lograr consolidarnos como una
            empresa seria y confiable, comprometida con nuestros valores, política
            de calidad y a través de nuestra gestión e intervención seguir
            contribuyendo al mejoramiento de la calidad de vida de nuestros
            clientes y al desarrollo económico , social y ambiental de la
            comunidad y del país.
          </p>
          <br />
        </div>
        <div>
            {/* valor */}
            {/* eslint-disable-next-line */}
            <a name="valor"/>
          <picture>
          <source media="(min-width:640px)" srcSet={valorm} />
            <img src={valor} alt="ventas" className="img-venta" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas">Valores</h3>
          {/* parrafo */}
          <p className="parrafos">
            Orientación al cliente: Centramos nuestros esfuerzos en la
            satisfacción del cliente, aportando soluciones competitivas y de
            calidad generando credibilidad y confianza
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Conducta ética: Actuamos con profesionalismo, integridad moral,
            lealtad y respeto a las personas.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Transparencia: Actuamos coherentemente con la información, siendo
            abierta , clara , oportuna y verificable.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Trabajo en equipo: Fomentamos la participación de todos para lograr un
            objetivo común, compartiendo información y conocimientos. Establecemos
            metas y evaluación de la gestión por resultados.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Innovación: Promovemos mejoras continuas e innovamos para alcanzar la
            máxima calidad en nuestros procesos a partir de criterios de
            rentabilidad.
          </p>
          <br />
          {/* parrafo */}
          <p className="parrafos">
            Formación Integral: Capacitación y actualización permanente de
            nuestros colaboradores en la legislación, normas y entidades
            gubernamentales que rigen el sector inmobiliario
          </p>
          <br />
        </div>
      </div>
    </React.Fragment>
  );
}

export { SobreNosotros };

import React from 'react';
import './GaleriaVentas.css'
import foto from'./casaPrueva.png'
import habitacion from './Habitaciones.png'
import bano from './Banos.png'
import metros from './metros.png'
import {Button} from '../Button'


function GaleriaVentas (data) {
return (
    <React.Fragment>
        
        <div id="cardVentas" className="card-ventas">
            <img src={foto} alt = 'casa' className='imagen-csa'/>
            <h3 className='valor'>$400.000.000</h3>
            <p className='localizacion'>Bogotá, barrio</p>
            <p className='localizacion'>Cod:0000</p>
            <div className='contenedor-ico'>
                <p className='habitaciones'><img src={habitacion} alt='ico' className='ico-hab'/> 3 Hab</p>
                <p className='baños'><img src={bano} alt='ico' className='ico-baño'/> baños</p>
                <p className='metros'><img src={metros} alt='ico' className='ico-metros'/> 300m²</p>
            </div>
            <div className="cont">
                <Button
                
                contenido='ver mas'
                ruta="/casa"
                />
            </div>
        </div>
        
    </React.Fragment>
)
}

export {GaleriaVentas};
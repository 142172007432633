import React from 'react';
import {Footerp} from '../Footerp'

import { InfoArriendo } from '../InfoCasa/InfoArriendo';
import {Bot} from '../servisiosSeparados/Bot'
import Whatsapp from '../Whatsapp'


function CasasArriendo() {
  
    return (
        <React.Fragment>
            <div className="">
            
            <InfoArriendo/>
            </div>
            <Bot/>
            <Footerp/>
            <div style={{position: 'fixed', bottom: 0, right: '0px', zIndex:'20000'}}>
             <Whatsapp/>
            </div>
        </React.Fragment>
    )
}

export{CasasArriendo}
import React from "react";
import flecha from "./flecha.png";


function AvaluosInfo() {
  return (
    <React.Fragment>
      {/* parrafo */}
      <p className="parrafo">
      A través de expertos peritos avaluadores con capacidad y formación profesional realizamos y preparamos avalúos de bienes inmuebles urbanos, rurales entre otros, mediante la aplicación de las buenas prácticas de valoración y siguiendo estándares de calidad, así como las Normas Técnicas Sectoriales (NTS) y Guías Técnicas Sectoriales (GTS) relacionadas con la actividad valuatoria.
      </p>
      <br />
      {/* subtitulos */}
      <h4 className="subtitulo">
        En nuestro proceso de gestión consideramos entre otros los siguientes
        avalúos: Avalúos Urbanos  
      </h4>
      <br />
      {/* lista */}
      <ul className="parrafo">
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Rurales</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Comerciales</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Industriales.</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos - Valoraciones Empresariales</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Maquinaria y Equipos</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Hipotecarios</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Monumentos Históricos</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Divisorios con base al nuevo código general del proceso</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Especiales</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos Certificados RAA</li>
        <br />
        <img className="flecha" src={flecha} alt="" />
        <li>Avalúos e Inventarios Activos Fijos bajo Normas NIIF</li>
        <br />
      </ul>
      {/* subtitulos */}
      <h4 className="subtitulo">Entrega de Informes de Avalúos en 48 horas </h4>
      <br />
    </React.Fragment>
  );
}

export { AvaluosInfo };

import React from "react";
import "./Footer.css";
import logo from "./Logof.png"
import {
  Link
} from "react-router-dom";



import { InstagramEmbed } from 'react-social-media-embed';

function Footerp() {

  
  
  return (
    <footer className="container-foot">
      <div className="centrado">
        <div className="div1">
        <picture>
          <img src={logo} alt="" className="log"/>
        </picture>
        </div>
        <ul className="list">
            <h3 className="ftitulo">JJC</h3>
            <li><Link to='/organizacion'>Organizacion</Link></li>
            <li><Link to='/servicios'>Servicios</Link></li>
            <li><Link to='/simuladores'>Simuladores</Link></li>
            {/* eslint-disable-next-line */}
           
        </ul>
        
        <div className="div3">
        <div class="fb-page" data-href="https://www.facebook.com/jjcinmobiliaria" data-tabs="timeline" data-width="" data-height="350" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/jjcinmobiliaria" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/jjcinmobiliaria">JJC Inmobiliaria</a></blockquote></div>





        {/* <div
          className="fb-page"
          data-href="https://www.facebook.com/jjcinmobiliaria"
          data-tabs="timeline"
          data-width="350"
          data-height="350"
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/jjcinmobiliaria"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/jjcinmobiliaria">
              JJC Inmobiliaria
            </a>
            </blockquote> 
            </div>
          */}
        </div>
        <div className="div4">
          <InstagramEmbed url="https://www.instagram.com/reel/Ce6XQMuFvm1/?utm_source=ig_web_copy_link"  height={345}/>
        </div>
        <div className="div5">
          <ol className="list">
            <h3 className="ftitulo">Contacto</h3>
            <li>(+57)(601) 2520857 – 3102405824</li>
            <li>Jjc-inmobiliaria@hotmail.com</li>
            <li>Inmobiliaria.jjc10@gmail.com</li>
          </ol>
        </div>


        
        {/* <div
          className="fb-page"
          data-href="https://www.facebook.com/jjcinmobiliaria"
          data-tabs="timeline"
          data-width="170"
          data-height="300"
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/jjcinmobiliaria"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/jjcinmobiliaria">
              JJC Inmobiliaria
            </a>
          </blockquote>
        </div> */}


      </div>
    </footer>
  );
}

export { Footerp };

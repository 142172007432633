import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";
import  down  from  './down.png'
import  up  from  './up.png'


function MenuServicios() {
  return (
    <React.Fragment>
      <div className="menucontainer-g">
        <ul className="menu-pri">
          <li className="f">
            <Link to="/menuorganizacion">Organizacion<img src={down} className="down" alt=""/></Link>
            </li>
          <li className="f">
            <Link to="/menu">Servicios<img src={up} className="down" alt=""/></Link>
            <ul className="s">
              <li>
                <Link to="/ventas">Ventas</Link>
              </li>
              <li>
                <Link to="/arriendos">Arrendamientos</Link>
              </li>
              <li>
                <Link to="/avaluos">Avalúos</Link>
              </li>
              <li>
                <Link to="/prestamos">Préstamo sobre hipoteca</Link>
              </li>
              <li>
                <Link to="/juridica">Asesorías jurídicas</Link>
              </li>
              <li>
                <Link to="/financiera">
                  Asesorías contables, Financieras, tributarias
                  </Link>
              </li>
              
              <li>
                <Link to="/arquitectura">Arquitectura y diseño</Link>
              </li>
            </ul>
          </li>
          <li className="f">
            <Link to="/menusimuladores">Simuladores<img src={down} className="down" alt=""/></Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export {MenuServicios };

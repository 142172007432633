import React from "react";
import vision from "./vision.png";
import visionm from "./visionm.png";
import {VisionInfo} from "./VisionInfo"
import  downd  from  './downd.png'
function Vision() {
  const [active, setActive]= React.useState(false)
  const onActive = () => {
    setActive(!active)
  }
  return (
    <React.Fragment>
      <div className="contenedorsobre">
        {/* eslint-disable-next-line */}
        <a name="vision" />
        <div>
          {/* imagen */}
          <picture>
            <source media="(min-width:640px)" srcSet={visionm} />
            <img src={vision} alt="ventas" className="img-venta" />
          </picture>
          {/* titulo */}
          <h3 className="titulo-ventas" onClick={onActive}>Visión<img src={downd} className={active? "downd-rote" : "downd" }  alt=""/></h3>
          <div className={active? "activete" : "non-activete" }>
            <VisionInfo/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export { Vision };

import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function formatNumber(number){
  return new Intl.NumberFormat( 'en-US',  {
  
 
  }    ).format(number)
}




export default function SimuladoreArrendamiento() {

  const [age, setAge] = React.useState('');
  const [arrendatario, setArrendatario] = useState('');
  const [destino, setDestino] = useState('');
  const [loading, setLoading] = useState(true);
  const [simuladorlist, setSimuladorlist] = useState([])
  const [nocalc, setSimular] = useState("")
  const [num, setNum] = useState("")
  const [numex, setNumex] = useState("")

  useEffect(() => {
    axios.get('api/simuladores-vista').then(res => {
      console.log(res.data.simuladores);
      if (res.status === 200) {
        setSimuladorlist(res.data.simuladores)
      }
      setLoading(false)

    })

  }, []);
  console.log(simuladorlist)


  if (loading) {
    return <h2>Cargando...</h2>
  }
  else {

  }




  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //  eslint-disable-next-line no-console


    let canon = parseInt(data.get('canon'))
    let expensas = ''
    if (data.get('expensas'))
      expensas = parseInt(data.get('expensas'))
    else {
      expensas = 0
    }
    let canonDeArrendamiento = suma(canon, expensas)
    let pagoAdmin = expensas
    let comicioninmobiliaria = canonDeArrendamiento * simuladorlist.map(simuladores => simuladores.renta_comision_inmo) / 100
    let ivaComicion = comicioninmobiliaria * simuladorlist.map(simuladores => simuladores.renta_IVA_sobre_comision) / 100
    let seguro = canonDeArrendamiento * simuladorlist.map(simuladores => simuladores.renta_seguro_arrendamiento) / 100
    let icaComision = simuladorlist.map(simuladores => simuladores.renta_rete_ICA_comision) * comicioninmobiliaria / 1000
    let icaArrendamiento = simuladorlist.map(simuladores => simuladores.renta_rete_ICA_arrendamiento) * canonDeArrendamiento / 1000
    let ivaArrendamiento = canon * simuladorlist.map(simuladores => simuladores.renta_IVA_sobre_arrendamiento) / 100
    let reteIvaComision = comicioninmobiliaria * simuladorlist.map(simuladores => simuladores.renta_rete_IVA_sobre_comision) / 100

    function createData(detalle, abonos, descuentos) {
      return { detalle, abonos, descuentos };
    }

    let totalmas = canonDeArrendamiento
    let totalmenos = expensas + comicioninmobiliaria + ivaComicion + seguro
    let rows = [
      createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
      createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
      createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
      createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
      createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
      createData("Retencion ICA comisión", `$ ${formatNumber  (icaComision)}`, ""),
      createData("Retencion ICA arrendamiento", "", `$ ${formatNumber (icaArrendamiento)}`),
      createData("IVA sobre arrendamiento", `$ ${formatNumber (ivaArrendamiento)}`, ""),
      createData("Retencion IVA sobre comisión", `$ ${formatNumber (reteIvaComision)}`, ""),
      createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
      createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, "")
    ]


    console.log({
      canon: data.get('canon'),
      expensas: data.get('expensas'),
      propietario: data.get('propietario'),
      arrendatario: data.get('arrendatario'),
      destino: data.get('destino'),

    });

    if (data.get('propietario') === '10' && data.get('arrendatario') === '10') {

      console.log(canonDeArrendamiento)
      console.log(pagoAdmin)
      console.log(comicioninmobiliaria)
      console.log(ivaComicion)
      console.log(seguro)

      let totalmas = canonDeArrendamiento
      let totalmenos = pagoAdmin + comicioninmobiliaria + ivaComicion + seguro
      rows = [
        createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
        createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
        createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
        createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
        createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
        createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
        createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, "")
      ]
    }
    else if (data.get('propietario') === '20' | '30' && data.get('arrendatario') === '10' && data.get('destino') === '10') {
      console.log(canonDeArrendamiento)
      console.log(pagoAdmin)
      console.log(comicioninmobiliaria)
      console.log(ivaComicion)
      console.log(seguro)
      console.log(icaComision)

      let totalmas = canonDeArrendamiento + icaComision
      let totalmenos = pagoAdmin + comicioninmobiliaria + ivaComicion + seguro  
      rows = [
        createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
        createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
        createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
        createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
        createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
        createData("Retencion ICA comisión", `$ ${formatNumber (icaComision)}`, ""),
        createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
        createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, ""),
        
        
      ]
    }

    else if (data.get('propietario') === '10' && data.get('arrendatario') === '20' | '30' && data.get('destino') === '10' | '20') {
      console.log(canonDeArrendamiento)
      console.log(pagoAdmin)
      console.log(comicioninmobiliaria)
      console.log(ivaComicion)
      console.log(seguro)
      console.log(icaArrendamiento)

      let totalmas = canonDeArrendamiento
      let totalmenos = pagoAdmin + comicioninmobiliaria + ivaComicion + seguro + icaArrendamiento
      rows = [
        createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
        createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
        createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
        createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
        createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
        createData("Retencion ICA arrendamiento", "", `$ ${formatNumber (icaArrendamiento)}`),
        createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
        createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, ""),
      ]
    }
    else if (data.get('propietario') === '20' | '30' && data.get('arrendatario') === '10' && data.get('destino') === '20') {
      console.log(canonDeArrendamiento)
      console.log(pagoAdmin)
      console.log(comicioninmobiliaria)
      console.log(ivaComicion)
      console.log(seguro)
      console.log(icaComision)
      console.log(ivaArrendamiento)

      let totalmas = canonDeArrendamiento + icaComision + ivaArrendamiento
      let totalmenos = pagoAdmin + comicioninmobiliaria + ivaComicion + seguro    
      rows = [
        createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
        createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
        createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
        createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
        createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
        createData("Retencion ICA comisión", `$ ${formatNumber (icaComision)}`, ""),
        createData("IVA sobre arrendamiento", `$ ${formatNumber (ivaArrendamiento)}`, ""),
        createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
        createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, ""),
      ]

    }
    else if (data.get('propietario') === '20' && data.get('arrendatario') === '20' | '30' && data.get('destino') === '10') {
      console.log(canonDeArrendamiento)
      console.log(pagoAdmin)
      console.log(comicioninmobiliaria)
      console.log(ivaComicion)
      console.log(seguro)
      console.log(icaComision)
      console.log(ivaArrendamiento)

      let totalmas = canonDeArrendamiento + icaComision  + reteIvaComision
      let totalmenos = pagoAdmin + comicioninmobiliaria + ivaComicion + seguro  + icaArrendamiento 
      rows = [
        createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
        createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
        createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
        createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
        createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
        createData("Retencion ICA arrendamiento", "", `$ ${formatNumber (icaArrendamiento)}`),
        createData("Retencion ICA comisión", `$ ${formatNumber (icaComision)}`, ""),
        createData("Retencion IVA sobre comisión 15%", `$ ${formatNumber (reteIvaComision)}`, ""),
        createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
        createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, "")
      ]

    }
    else if (data.get('propietario') === '20' | '30' && data.get('arrendatario') === '20' | '30' && data.get('destino') === '20') {
      console.log(canonDeArrendamiento)
      console.log(pagoAdmin)
      console.log(comicioninmobiliaria)
      console.log(ivaComicion)
      console.log(seguro)
      console.log(ivaArrendamiento)
      console.log(icaComision)
      console.log(icaArrendamiento)
      console.log(reteIvaComision)

      let totalmas = canonDeArrendamiento + ivaArrendamiento + reteIvaComision + icaComision
      let totalmenos = pagoAdmin + comicioninmobiliaria + ivaComicion + seguro   + icaArrendamiento 
      rows = [
        createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
        createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
        createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
        createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
        createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
        createData("Retencion ICA arrendamiento", "", `$ ${formatNumber (icaArrendamiento)}`),
        createData("Retencion ICA comisión", `$ ${formatNumber (icaComision)}`, ""),
        createData("IVA sobre arrendamiento", `$ ${formatNumber (ivaArrendamiento)}`, ""),
        createData("Retencion IVA sobre comisión", `$ ${formatNumber (reteIvaComision)}`, ""),
        createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
        createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, "")
      ]



    }
    else if (data.get('propietario') === '30' && data.get('arrendatario') === '20'|'30'  && data.get('destino') === '10') {
      console.log(canonDeArrendamiento)
      console.log(pagoAdmin)
      console.log(comicioninmobiliaria)
      console.log(ivaComicion)
      console.log(seguro)
      console.log(ivaArrendamiento)
      console.log(icaComision)
      console.log(icaArrendamiento)
      console.log(reteIvaComision)

      let totalmas = canonDeArrendamiento  + icaComision
      let totalmenos = pagoAdmin + comicioninmobiliaria + ivaComicion + seguro   + icaArrendamiento 
      rows = [
        createData("Canon de arremdamiento y admon", `$ ${formatNumber(canonDeArrendamiento)}`, ''),
        createData("Pago administracion", '', `$ ${formatNumber (pagoAdmin)}`),
        createData("Comisión inmobiliaria", '', `$ ${formatNumber (comicioninmobiliaria)}`),
        createData("iva sobre comisión", '', `$ ${formatNumber (ivaComicion)}`),
        createData("seguro de arrendamiento", "", `$ ${formatNumber (seguro)}`),
        createData("Retencion ICA arrendamiento", "", `$ ${formatNumber (icaArrendamiento)}`),
        createData("Retencion ICA comisión", `$ ${formatNumber (icaComision)}`, ""),
        createData("Sub total", `$ ${formatNumber (totalmas)}`, `$ ${formatNumber (totalmenos)}`),
        createData("Total aproximado mensual a recibir", `$ ${formatNumber (totalmas - totalmenos)}`, "")
      ]



    }
    if(data.get('canon')){
      setSimular(
        <TableContainer component={Paper} >
          <Table sx={{ }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Detalles</TableCell>
                <TableCell align="right">Abonos</TableCell>
                <TableCell align="right">Descuentos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.detalle}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  
                >
                  <TableCell component="th" scope="row">
                    {row.detalle}
                  </TableCell>
                  <TableCell align="right"><Typography>{ row.abonos}</Typography></TableCell>
                  <TableCell align="right"><Typography color="error">{row.descuentos}</Typography></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
      
    } else {
      setSimular(
        <Typography sx={{m: 2}} margin="6" variant="subtitle1" component="div" color="red">
              el campo de "canon" es requerido
            </Typography>
      )
    }
    
  }





  const handleChangeage = (event) => {
    setAge(event.target.value);
  };


  const handleChangearr = (event) => {
    setArrendatario(event.target.value);
  };

  const handleChangedes = (event) => {
    setDestino(event.target.value);
  };


  const handleChangenum = (event) => {
    
    setNum(formatNumber(event.target.value))
  };
  const handleChangenumex = (event) => {
    
    setNumex(formatNumber(event.target.value))
  };

  const suma = (canon, expensas) => {

    let resultadoSuma = canon + expensas
    return resultadoSuma
  }





  return (
    <Box onSubmit={handleSubmit} noValidate component="form" sx={{
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

    }}>
      <Card sx={{ maxWidth: 1275 }}>
        <CardContent>

          <Box sx={{ mt: 1 }}>
            <Typography variant="h5" component="div">
              Simulador de Arriendos
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              A continuación encontrara un simulador de renta para que tenga una idea aproximada del valor a recibir mensualmente La información marcada con * es necesaria
            </Typography>
            <TextField
              label="canon de arrendamiento"
              required
              id="canon"
              name="canon"
              helperText={`$ ${num}`}
              // type="number"
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={handleChangenum}
            >
            </TextField>
            
            <TextField
              label="Expensas de administracion"
              id="expensas"
              name="expensas"
              helperText={`$ ${numex}`}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={handleChangenumex}
            />

          </Box>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="propietario"
              value={age}
              label="Regimen tributario propietario"
              onChange={handleChangeage}
            >
              
              <MenuItem value={10}>Simple</MenuItem>
              <MenuItem value={20}>Común</MenuItem>
              <MenuItem value={30}>Gran contribuyente</MenuItem>

            </Select>
            <FormHelperText>Regimen tributario propietario</FormHelperText>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={arrendatario}
              label="Age"
              name="arrendatario"
              onChange={handleChangearr}
            >
              
              <MenuItem value={10}>Simple</MenuItem>
              <MenuItem value={20}>Común</MenuItem>
              <MenuItem value={30}>Gran contribuyente</MenuItem>

            </Select>
            <FormHelperText>Regimen tributario Arrendatario</FormHelperText>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Seleccione</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={destino}
              label="Age"
              name="destino"
              onChange={handleChangedes}
            >
              
              <MenuItem value={10}>Vivienda</MenuItem>
              <MenuItem value={20}>Comercio</MenuItem>

            </Select>
            <FormHelperText>Destino del inmueble</FormHelperText>
          </FormControl>

        </CardContent>
        <CardActions>
          <Button type="submit" variant="contained" >Calcular</Button>
        </CardActions>
        <span>
          {nocalc}
        </span>
      </Card>
    </Box>
  );
}

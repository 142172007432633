
import React from 'react';
import './Header.css'
import Logo from './Logo.png'
import Menu from './Menu.png'
import {
  Link
} from "react-router-dom";


function Header () {
  return  (
    <header className = "header">
      <div className="headermax">
        <div className="box">

        <Link to='/'>
        <img src = {Logo} alt = 'logo' className='header-logo'/>
        </Link>
        </div>
        <nav className="nav">
          
        <Link to='/menu'><img src={Menu} alt='menu'className='header-menu'/></Link>
          
          <ul className="navbar">

          <li className="navbar-li quienes-somos"><Link to='/organizacion'>¿Quiénes somos?</Link>
          <ul className="submenu">
              <li>
                <Link to="/sobrenosotros">Sobre nosotros</Link>
              </li>
              <li>
                <Link to="/mision">Misión</Link>
              </li>
              <li>
                <Link to="/vision">Visión</Link>
              </li>
              <li>
                <Link to="/valor">Valores</Link>
              </li>
            </ul>
          </li>

            <li className="navbar-li"><Link to='/servicios'>Servicios</Link>
              <ul className ="submenu">
                <li><Link to='/ventas'>Ventas</Link></li>
                <li><Link to='/arriendos'>Arrendamientos</Link></li>
                <li><Link to='/avaluos'>Avalúos</Link></li>
                <li><Link to='/prestamos'>Préstamo sobre hipoteca</Link></li>
                <li><Link to='/juridica'>Asesorías jurídicas</Link></li>
                <li><Link to='/financiera'>Asesorías contables, Financieras, tributarias</Link></li>
                <li><Link to='/arquitectura'>Arquitectura y diseño</Link></li>
              </ul>
            </li>
            <li className="navbar-li">Simuladores
              <ul className ="submenu">
                <li><Link to='/arrendamiento'>simulador arriendo</Link></li>
                <li><Link to='/GastosNotariales'>simulador notariales</Link></li>
                <li><Link to='/GastosHipoteca'>simulador hipotecas</Link></li>
                {/* eslint-disable-next-line */}
                <li><a target="_blank" rel="noreferrer" href="https://www.bancodeoccidente.com.co/solicitarcredito/#/">simulador crédito bancario</a></li>
              </ul>
            </li>
            {/* <li className="navbar-li"><Link to='/'>Novedades</Link></li>
            <li className="navbar-li"><Link to='/'>Contactenos</Link></li> */}
            
          </ul>
        </nav>
      </div>
      </header>
  )
}



export {Header};